import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

export type GetAllFontsResponse = {
  collection: {
    [key: string]: {
      css_url: string
      name: string
      type: 'generic' | 'google'
    }
  }
}

export const useGetFontsList = () => {
  const { data } = useSWRImmutable<GetAllFontsResponse, AxiosError>(`fonts-all`)

  const dataAsArray = data ? Object.values(data.collection) : []
  return { data, dataAsArray }
}
