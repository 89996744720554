import { CurrencyType, PLN } from '@constants/currencies'
import { Badge } from '@ui-kit/Badge'
import { Icon } from '@ui-kit/Icon'
import { Price as PriceComponent } from '@ui-kit/Price'
import { PriceDescription } from '@ui-kit/Price/PriceDescription'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export interface PriceProps {
  price: {
    amount_net: number
    currency: CurrencyType
  }
}

export const Price: FC<PriceProps> = ({ price }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <PriceComponent amount={price.amount_net} currency={price.currency}>
        <PriceDescription data-testid='price-currency-net'>
          {price.currency === PLN && t('change.plan.page.net')}
        </PriceDescription>
      </PriceComponent>

      <Spacer space={10} />

      <Badge variant='calmviolet-border'>
        <Icon icon='icon-credit-card' />

        <Spreader spread={5} />

        {t('addons.store.badge.one.off.payment')}
      </Badge>
    </Fragment>
  )
}
