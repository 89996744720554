import { PLANS } from '@constants/plans'

export const FREE_TRIAL_WITH_CREDIT_CARD = 'freeTrialWithCreditCard'
export const FREE_TRIAL_WITHOUT_CREDIT_CARD = 'freeTrialWithoutCreditCard'
export const PAID_ACCOUNT = 'paidAccount'

export type AccountVariantsTypes =
  | typeof FREE_TRIAL_WITH_CREDIT_CARD
  | typeof FREE_TRIAL_WITHOUT_CREDIT_CARD
  | typeof PAID_ACCOUNT

export type OptionsTypes = {
  text: string
  url: string
}

export const linkParts = {
  [PLANS.CORE_20]: 'core-20',
  [PLANS.CORE_21]: 'core-21',
  [PLANS.CREATE_20]: 'create-20',
  [PLANS.CREATE_21]: 'create-21',
  [PLANS.AUTOMATE_20]: 'automate-20',
  [PLANS.AUTOMATE_21]: 'automate-21',
  [PLANS.AGENCY_20]: 'agency-20',
  [PLANS.AGENCY_21]: 'agency-21',
  [PLANS.AGENCY_22]: 'agency',
  [PLANS.LITE_22]: 'lite',
  [PLANS.LITE_DISC_22]: 'lite',
  [PLANS.PROFESSIONAL_22]: 'professional',
  [PLANS.PROFESSIONAL_DISC_22]: 'professional',
  [PLANS.BR_PROFESSIONAL_22]: 'brprofessional',
  [PLANS.BR_ESSENTIAL_22]: 'bressential',
  [PLANS.BR_PAGEX_22]: 'brpagex',
  [PLANS.UNLIMITED_22]: 'unlimited'
}

export const specificPlansLinkParts = {
  [PLANS.PROFESSIONAL_24]: 'professional24',
  [PLANS.PROFESSIONAL_100K_23]: 'professional100k',
  [PLANS.PROFESSIONAL_200K_23]: 'professional200k',
  [PLANS.PROFESSIONAL_500K_23]: 'professional500k',
  [PLANS.PROFESSIONAL_1M_23]: 'professional1m',
  [PLANS.AGENCY_22]: 'agency22',
  [PLANS.AGENCY_100K_23]: 'agency_uv100k23',
  [PLANS.AGENCY_200K_23]: 'agency_uv200k23',
  [PLANS.AGENCY_500K_23]: 'agency_uv500k23',
  [PLANS.AGENCY_1M_23]: 'agency_uv1m23'
}
