import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { PLANS } from '@constants/plans'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { CustomLink } from '@landingi/landingi-ui-kit'
import { ACCOUNT } from '@routes/path'
import { Spacer } from '@ui-kit/Spacer'
import i18next from 'i18next'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAccountVariant } from '../hooks/useGetAccountVariant'
import {
  AccountVariantsTypes,
  FREE_TRIAL_WITH_CREDIT_CARD,
  FREE_TRIAL_WITHOUT_CREDIT_CARD,
  linkParts,
  OptionsTypes,
  PAID_ACCOUNT,
  specificPlansLinkParts
} from '../types'

export const ExtendLink = () => {
  const { t } = useTranslation()
  const { language } = i18next

  const { planRecordKey, userEmail, isFreePlan } = useUserContext()

  const hasAccessToExtendLimits = useAcl('change_plan.extend_limit')
  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const hasNotAccessToAddons = useFeatureFlags('ADDONS_DISABLED')

  const isUnlimitedPlan = planRecordKey === PLANS.UNLIMITED_22

  let isPlanRecordKey

  if (planRecordKey !== undefined) isPlanRecordKey = planRecordKey in linkParts

  const accountVariant = useGetAccountVariant({
    paidAccountACL:
      hasAccessToExtendLimits && (!isUnlimitedPlan || hasNotAccessToAddons),
    freeTrialWithCreditCardACL: hasAccessToChangePlan,
    freeTrialWithoutCreditCardACL: hasAccessToChangePlan
  })

  const specificPlanUrl =
    specificPlansLinkParts[planRecordKey as keyof typeof specificPlansLinkParts]

  const paidUrl = specificPlanUrl
    ? t('topbar.my-usage.addon.specific.plan.url', {
        plan: specificPlanUrl,
        email: userEmail,
        planRecordKey
      })
    : t('topbar.my-usage.addon.url', {
        plan: linkParts[planRecordKey as keyof typeof linkParts],
        lang: language,
        email: userEmail,
        planRecordKey
      })

  const linkOptions: {
    [key in AccountVariantsTypes]: OptionsTypes
  } = {
    [FREE_TRIAL_WITH_CREDIT_CARD]: {
      text: t('topbar.my-usage.change-plan'),
      url: `${NEW_LANDINGI_APP_URL}${ACCOUNT.ACCOUNT_PACKAGE}`
    },
    [FREE_TRIAL_WITHOUT_CREDIT_CARD]: {
      text: t('topbar.my-usage.change-plan'),
      url: `${NEW_LANDINGI_APP_URL}${ACCOUNT.ACCOUNT_PACKAGE}`
    },
    [PAID_ACCOUNT]: {
      text: t('topbar.my-usage.extend-limits'),
      url: paidUrl
    }
  }

  return !isFreePlan && isPlanRecordKey && accountVariant ? (
    <Fragment>
      <Spacer space={10} />

      <CustomLink
        href={linkOptions[accountVariant].url}
        label={linkOptions[accountVariant].text}
        target='_blank'
        size={12}
        underlined
      />
    </Fragment>
  ) : null
}

ExtendLink.displayName = 'TopbarMyUsageButtonsExtendLink'
