import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import useSteps from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/useSteps'
import { Button, Heading, Paragraph, Spacer } from '@ui-kit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { StyleBox } from './components/StyleBox'
import styles from './Fourth.module.scss'

export const FourthStep: React.FC = () => {
  const { t } = useTranslation()
  const { validators } = useWizardContext()
  const { isFourthStepValid } = validators
  const { nextStep } = useSteps()

  return (
    <div>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.is.your.style')}
      </Heading>

      <Spacer space={20} />

      <Paragraph size={14} color='neutral-6'>
        {t(
          'landings.create.wizard.landing.modal.our.designers.have.thoughtfully'
        )}
      </Paragraph>

      <Spacer space={20} />

      <div className={styles['fourth-step__grid']}>
        <StyleBox lpStyle='modern' />
        <StyleBox lpStyle='dark' />
        <StyleBox lpStyle='minimal' />
        <StyleBox lpStyle='elegant' />
      </div>

      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button
          icon='icon-arrow-right'
          disabled={!isFourthStepValid}
          onClick={nextStep}
        >
          {t('landings.create.wizard.landing.modal.go.to.color.palletes')}
        </Button>
      </Row>
    </div>
  )
}
