import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { PAYMENTS } from '@routes/path'
import { Button } from '@ui-kit'
import { FC, Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

interface PaymentsCardExpiredInProps {
  days: number
}

export const PaymentsCardExpiredIn: FC<PaymentsCardExpiredInProps> = ({
  days
}) => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const handleOpenPaymentsPage = () => {
    window.open(`${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`, '_self')
  }

  return (
    <Fragment>
      {/* use InfobarContent component instead of <span> tag */}
      <span className={styles.alert__content}>
        <Trans
          i18nKey='payments.card.expires.few-days'
          values={{
            count: days
          }}
          components={{
            b: <b />
          }}
        />
      </span>

      {hasAccessToPayments && (
        <Row>
          <Button size={14} onClick={handleOpenPaymentsPage}>
            {t('payments.change.payment.method')}
          </Button>
        </Row>
      )}
    </Fragment>
  )
}

PaymentsCardExpiredIn.displayName =
  'TopbarInfobarCreditCardExpiresAtTypePaymentsCardExpiredIn'
