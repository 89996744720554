import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { Badge, Icon, Spreader, Tooltip } from '@landingi/landingi-ui-kit'
import styles from '@pages/Landings/routes/Dashboard/components/Header/Info/Info.module.scss'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { SMART_SECTIONS } from '@routes/path'
import LANDINGS from '@routes/path/landings'
import { Badge as BadgeNew } from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

export const Badges = () => {
  const { landing } = useDashboardContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    smart_sections_count,
    group_name,
    group_uuid,
    schedule_enabled,
    camino_lid,
    name,
    identifier,
    new_camino_lid,
    test_enabled,
    is_programmatic
  } = landing

  const handleSmartSection = () => {
    const smartSectionListURL = `${NEW_LANDINGI_APP_URL}${SMART_SECTIONS.SMART_SECTIONS}?filterName=${name}&uuid=${identifier}&order=newest`

    window.open(smartSectionListURL)
  }

  const handleGroup = () => {
    if (!group_uuid) return

    navigate(generatePath(LANDINGS.LANDINGS_GROUP, { identifier: group_uuid }))
  }

  const handleOptimization = () =>
    navigate(generatePath(LANDINGS.LANDINGS_AB, { identifier }))

  const handleSchedule = () =>
    navigate(generatePath(LANDINGS.LANDINGS_SCHEDULE, { identifier }))

  return (
    <div>
      {smart_sections_count ? (
        <span className={styles.landing__badge} onClick={handleSmartSection}>
          <Badge type='info'>
            {t('word.smartsection.count', {
              count: smart_sections_count
            })}
          </Badge>
        </span>
      ) : null}

      {group_name ? (
        <span className={styles.landing__badge} onClick={handleGroup}>
          <Badge type='accent-6' tooltip={group_name}>
            <Icon icon='icon-folder' color='white' size={12} />

            <Spreader spread='tiny' />

            {group_name}
          </Badge>
        </span>
      ) : null}

      {test_enabled ? (
        <span className={styles.landing__badge} onClick={handleOptimization}>
          <Badge type='accent-3'>
            <Icon icon='icon-random' color='white' size={12} />

            <Spreader spread='tiny' />

            {t('word.test')}
          </Badge>
        </span>
      ) : null}

      {is_programmatic && (
        <BadgeNew variant='warmpink-filled' icon='icon-programmatic'>
          PROGRAMMATIC
        </BadgeNew>
      )}

      {schedule_enabled ? (
        <span className={styles.landing__badge} onClick={handleSchedule}>
          <Badge type='accent-4'>
            <Icon icon='icon-calendar' color='white' size={12} />

            <Spreader spread='tiny' />

            {t('word.schedule')}
          </Badge>
        </span>
      ) : null}

      {camino_lid || new_camino_lid ? (
        <Tooltip
          content={t('landings.dashboard.camino.tooltip', {
            lid: camino_lid
          })}
          placement='top'
          align='center'
          disabled={Boolean(new_camino_lid)}
        >
          <Badge type='accent-5'>
            <Icon icon='icon-extension' color='white' size={12} />

            <Spreader spread='tiny' />

            {t('landings.dashboard.camino.title')}
          </Badge>
        </Tooltip>
      ) : null}
    </div>
  )
}

Badges.displayName = 'LandingDashboardHeaderBadges'
