import { useStyles } from '@hooks/useStyles'
import { ColorType } from '@ui-kit/types/color'
import { FC } from 'react'

import css from './editor-icons.module.scss'
import styles from './Icon.module.scss'

export interface IconProps {
  icon: string
  color?: ColorType
  className?: string | string[]
  spin?: boolean
  size?: 8 | 10 | 12 | 14 | 15 | 16 | 20 | 25 | 26
  'data-testid'?: string
}

export const Icon: FC<IconProps> = ({
  icon,
  /**
   * default color is not defined to allow inheritance color icon from parent color
   */
  color,
  className = '',
  spin = false,
  size,
  'data-testid': dataTestId = 'icon'
}) => {
  const iconStyles = useStyles(
    {
      [css['editor-icon']]: true,
      [css[`${icon}`]]: true,
      [styles.icon]: true,
      [styles[`icon--size-${size}`]]: !!size,
      [styles[`icon--color-${color}`]]: !!color,
      [styles['icon--spin']]: spin
    },
    className
  )

  return <i className={iconStyles} data-testid={dataTestId} />
}

Icon.displayName = 'Icon'
