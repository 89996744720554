import { useStyles } from '@hooks/useStyles'
import { Paragraph } from '@ui-kit/Paragraph'
import { FC } from 'react'
import { Row } from 'simple-flexbox'

import { COUNTDOWN } from './constants'
import { CountdownBox } from './CountdownBox'
import styles from './CountdownTimer.module.scss'
import { useCountdown } from './hooks/useCountdown'

interface CountdownTimerProps {
  startDate?: string
  endDate: string
  variant?: 'info' | 'success'
  isLoading?: boolean
  className?: string | string[]
}

const renderColon = () => (
  <Paragraph size={16} color='white' weight={600}>
    :
  </Paragraph>
)

export const CountdownTimer: FC<CountdownTimerProps> = ({
  startDate,
  endDate,
  variant = 'info',
  isLoading,
  className
}) => {
  const { days, hours, minutes } = useCountdown({ startDate, endDate })

  const countdownStyles = useStyles(
    {
      [styles['countdown-timer']]: true,
      [styles['countdown-timer--info']]: variant === 'info',
      [styles['countdown-timer--success']]: variant === 'success'
    },
    className
  )

  return (
    <Row
      className={countdownStyles}
      horizontal='center'
      vertical='center'
      data-testid='countdown-timer'
    >
      <CountdownBox value={days} type={COUNTDOWN.DAYS} isLoading={isLoading} />

      {renderColon()}

      <CountdownBox
        value={hours}
        type={COUNTDOWN.HOURS}
        isLoading={isLoading}
      />

      {renderColon()}

      <CountdownBox
        value={minutes}
        type={COUNTDOWN.MINUTES}
        isLoading={isLoading}
      />
    </Row>
  )
}
