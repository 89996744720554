import { useStyles } from '@hooks/useStyles'
import { Button } from '@ui-kit/Button'
import { useModalContext } from '@ui-kit/Modal/context'
import { Spacer } from '@ui-kit/Spacer'
import { FC, Fragment, HTMLAttributes, MouseEventHandler } from 'react'

import styles from './ModalActions.module.scss'

type ActionType = {
  i18nTitle: string
  onClick: MouseEventHandler
  isLoading?: boolean
}

interface ModalActionsProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  primaryAction: ActionType
  secondaryAction?: ActionType
  className?: string | string[]
  'data-testid'?: string
}

export const ModalActions: FC<ModalActionsProps> = ({
  primaryAction,
  secondaryAction,
  className = '',
  'data-testid': dataTestId = 'modal-actions',
  ...restProps
}) => {
  const { isCentered } = useModalContext()

  const modalActionsStyles = useStyles(
    {
      [styles.modalActions]: true,
      [styles['modalActions--centered']]: isCentered
    },
    className
  )

  return (
    <Fragment>
      <Spacer space={30} />

      <div
        {...restProps}
        className={modalActionsStyles}
        data-testid={dataTestId}
      >
        {secondaryAction ? (
          <Button
            variant='text-primary'
            onClick={secondaryAction.onClick}
            data-testid='cancel-button'
            isLoading={secondaryAction.isLoading}
          >
            {secondaryAction.i18nTitle}
          </Button>
        ) : null}

        <Button
          variant='primary'
          onClick={primaryAction.onClick}
          data-testid='confirm-button'
          isLoading={primaryAction.isLoading}
        >
          {primaryAction.i18nTitle}
        </Button>
      </div>
    </Fragment>
  )
}

ModalActions.displayName = 'ModalActions'
