import {
  Button,
  emitTimingToastToggle,
  InputForm,
  Paragraph,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { REGISTRATION } from '@routes/path'
import { verify2FACode } from '@services/authentication'
import { useFormik } from 'formik'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

import FormSchema from './FormSchema'

/**
 * TwoFactorAuthenticationForm - stateless presentational component
 * @return {object} An object of children
 */
const TwoFactorAuthenticationForm = () => {
  const { t } = useTranslation()
  const { lang } = useRegisterContext()

  const initialValues = {
    code: ''
  }

  const { mutate } = useSWRConfig()

  const onSubmit = async (values, { setFieldError }) => {
    const { code } = values

    try {
      await verify2FACode(code)

      mutate('auth')
    } catch {
      emitTimingToastToggle(
        t('two.factor.authentication.this.code.is.invalid'),
        'alert'
      )
      setFieldError('code', t('two.factor.authentication.wrong.code.error'))
    }
  }

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: FormSchema()
  })

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <Row>
          <Column flexGrow='1'>
            <InputForm
              field={{
                name: 'code',
                value: values.code,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              i18n={{
                label: t('two.factor.authentication.6.digit.code'),
                placeholder: t('two.factor.authentication.6.digit.code')
              }}
              maxLength={6}
              form={{
                errors,
                touched
              }}
            />
          </Column>
        </Row>

        <Button
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          size='large'
        >
          {t('two.factor.authentication.verify')}
        </Button>

        <Fragment>
          <Spacer space='mini' />

          <Spacer space='tiny' />

          <Paragraph size={12} color='accent-2' align='center'>
            {t('two.factor.authentication.dont.have.mobile.device')}
            <Link to={`${REGISTRATION.RECOVERY_CODE.DEFAULT}?lang=${lang}`}>
              <Trans i18nKey='two.factor.authentication.dont.have.mobile.device.link' />
            </Link>
          </Paragraph>
        </Fragment>
      </Column>
    </form>
  )
}

TwoFactorAuthenticationForm.displayName = 'TwoFactorAuthenticationForm'

export default TwoFactorAuthenticationForm
