import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { useInputHelpers } from './hooks/useInputHelpers'
import { Input, InputProps } from './Input'

export interface Props extends Omit<InputProps, 'value'> {
  onConfirm: (value: number) => void
  valueToSet: number
  'data-testid'?: string
}

export const IntegerInput: FC<Props> = ({
  onConfirm,
  valueToSet,
  min,
  max,
  'data-testid': dataTestId = 'sidebar-icon',
  ...restProps
}) => {
  const { escapeNotAllowedKeysFromNaturalNumberInput } = useInputHelpers()

  const [value, setValue] = useState<string | number>(valueToSet)
  const valueRef = useRef(value)
  const isFocused = useRef(false)

  const handleConfirm = useCallback(() => {
    isFocused.current = false

    const maximumValue = typeof max === 'number' ? max : Infinity
    const minimumValue = typeof min === 'number' ? min : -Infinity

    const confirmValue = Math.min(
      Math.max(Number(valueRef.current), minimumValue),
      maximumValue
    )

    onConfirm(confirmValue)
    setValue(`${confirmValue}`)
    valueRef.current = confirmValue
  }, [onConfirm, min, max])

  useEffect(() => {
    return () => {
      if (isFocused.current) {
        handleConfirm()
      }
    }
  }, [handleConfirm])

  useEffect(() => {
    setValue(valueToSet)
    valueRef.current = valueToSet
  }, [valueToSet])

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    valueRef.current = event.target.value
    setValue(event.target.value)
  }

  const handleFocus = () => {
    isFocused.current = true
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      event.preventDefault()

      let currentValue = Number(value)

      if (
        event.key === 'ArrowUp' &&
        typeof max === 'number' &&
        currentValue < max
      ) {
        currentValue += 1
      }

      if (
        event.key === 'ArrowDown' &&
        typeof min === 'number' &&
        currentValue > min
      ) {
        currentValue -= 1
      }

      valueRef.current = currentValue
      handleConfirm()
      return
    }

    if (event.key === 'Enter') {
      handleConfirm()

      return
    }

    escapeNotAllowedKeysFromNaturalNumberInput(event)
  }

  const detectError =
    value === '' ||
    (typeof min === 'number' && Number(value) < min) ||
    (typeof max === 'number' && Number(value) > max)

  return (
    <Input
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleConfirm}
      onFocus={handleFocus}
      hasError={detectError}
      data-testid={dataTestId}
      type='number'
      {...restProps}
    />
  )
}

IntegerInput.displayName = 'SidebarInput'
