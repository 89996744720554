import { PROD } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useGetAppcuesData } from '@services/appcues'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const isAppcuesShowingPeriodFinished = (createdAt: string) => {
  const accountCreateDate = new Date(createdAt)

  // add 21 days to the account creation date
  const appcuesEndDate = new Date(
    accountCreateDate.getTime() + 21 * 24 * 60 * 60 * 1000
  )

  return appcuesEndDate < new Date()
}

export const useAppcues = () => {
  const calledOnce = useRef(false)
  const location = useLocation()

  const { data } = useGetAppcuesData()

  const { isAuth, userID, isLoading, isSupportMode, createdAt, expiresAt } =
    useUserContext()

  const hasAccessToAppcues = useFeatureFlags('APPCUES')
  const hasAccessToBasicAppcues = useFeatureFlags('BASIC_APPCUES')
  const hasAccessToSPA = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  const hasAppcuesFeatureFlag = hasAccessToBasicAppcues || hasAccessToAppcues

  useEffect(() => {
    if (
      !PROD ||
      !isAuth ||
      isLoading ||
      isSupportMode ||
      !hasAppcuesFeatureFlag ||
      !createdAt ||
      !expiresAt?.formatted ||
      isAppcuesShowingPeriodFinished(createdAt.formatted) ||
      !data ||
      !hasAccessToSPA ||
      calledOnce.current
    ) {
      return
    }

    // @ts-ignore
    Appcues?.identify(userID, data)

    // @ts-ignore
    Appcues?.page()

    calledOnce.current = true
  }, [
    data,
    isAuth,
    userID,
    hasAppcuesFeatureFlag,
    isSupportMode,
    createdAt,
    expiresAt,
    isLoading,
    hasAccessToSPA,
    location
  ])
}
