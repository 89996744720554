import { AxiosError } from 'axios'
import useSWR from 'swr'

interface GetSignInDateRequest {
  action: string
}

export interface GetSignInDateResponse {
  date: string | null
}

export const useGetSignInDate = ({ action }: GetSignInDateRequest) =>
  useSWR<GetSignInDateResponse, AxiosError>(
    `auth/sign-in-date/get-date?action=${action}`
  )
