import { useStyles } from '@hooks/useStyles'
import { useDialogContext } from '@ui-kit/Dialog/context'
import { FC, ReactNode } from 'react'

import styles from './DialogContent.module.scss'

interface DialogContentProps {
  className?: string | string[]
  children: ReactNode
  'data-testid'?: string
}

export const DialogContent: FC<DialogContentProps> = ({
  className,
  children,
  'data-testid': dataTestId = 'dialog-content'
}) => {
  const { size } = useDialogContext()

  const dialogContentStyles = useStyles(
    {
      [styles[`dialog__content--space-${size}`]]: true
    },
    className
  )

  return (
    <div data-testid={dataTestId} className={dialogContentStyles}>
      {children}
    </div>
  )
}

DialogContent.displayName = 'DialogContent'
