import { useStyles } from '@hooks/useStyles'
import { Heading } from '@ui-kit/Heading'
import { Icon } from '@ui-kit/Icon'
import { Panel } from '@ui-kit/Panel'
import { SpaceType } from '@ui-kit/types/space'
import { FC, MouseEventHandler, ReactNode, useCallback, useState } from 'react'

import styles from './Accordion.module.scss'

export interface AccordionProps {
  className?: string | string[]
  title: ReactNode
  content: ReactNode
  isOpenByDefault?: boolean
  padding?:
    | {
        x: SpaceType
        y: SpaceType
      }
    | number
  'data-testid'?: string
}

export const Accordion: FC<AccordionProps> = ({
  className = '',
  title,
  content,
  isOpenByDefault = false,
  padding = 15,
  'data-testid': dataTestId = 'accordion'
}) => {
  const [isOpen, setOpen] = useState(isOpenByDefault)

  const handleOpen = useCallback(() => setOpen(!isOpen), [isOpen])

  const itemStyles = useStyles(
    {
      [styles.accordion__item]: true
    },
    className
  )

  const titleStyles = useStyles({
    [styles['accordion__item-title']]: true
  })

  const headingStyles = useStyles({
    [styles['accordion__item-heading']]: true
  })

  const contentStyles = useStyles({
    [styles['accordion__item-content']]: true,
    [styles['accordion__item-content--open']]: isOpen,
    [styles['accordion__item-content--close']]: !isOpen
  })

  const handleContentClick: MouseEventHandler<HTMLDivElement> = event =>
    event.stopPropagation()

  return (
    <Panel
      className={itemStyles}
      padding={padding}
      data-testid={dataTestId}
      onClick={handleOpen}
    >
      <div className={titleStyles} data-testid='accordion-item-title'>
        <Heading level={3} className={headingStyles}>
          {title}
        </Heading>

        <Icon
          icon={isOpen ? 'icon-angle-up' : 'icon-angle-down'}
          className={styles['accordion__item-icon']}
        />
      </div>

      <div
        className={contentStyles}
        data-testid='accordion-item-content'
        onClick={handleContentClick}
      >
        {content}
      </div>
    </Panel>
  )
}

Accordion.displayName = 'Accordion'
