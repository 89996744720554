import { FREE_PLANS } from '@constants/plans'
import { useUserContext } from '@contexts/user'
import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import CreditCardStepForm from '@pages/Authentication/routes/CreditCardStep/CreditCardStepForm'
import { motion } from 'framer-motion'
import { PropTypes } from 'prop-types'
import { Fragment, Suspense, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import styles from './CreditCardStep.module.scss'

const FreePlanHeader = () => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Heading level={1}>
        {t('registration.flow.credit.card.step.start.free')}
      </Heading>

      <Trans
        i18nKey='registration.flow.credit.card.step.your.plan.completely.free'
        components={{
          b: <b />,
          p: <Paragraph size={16} line={28} />
        }}
      />
    </Fragment>
  )
}
const PaidPlanHeader = ({ freeTrialDays }) => (
  <Fragment>
    <Heading level={1}>
      <Trans
        i18nKey='registration.flow.credit.card.step.start.trial'
        values={{ days: freeTrialDays }}
      />
    </Heading>

    <Paragraph size={16} line={28}>
      <Trans i18nKey='registration.flow.credit.card.step.completely.free' />
    </Paragraph>
  </Fragment>
)

PaidPlanHeader.propTypes = {
  freeTrialDays: PropTypes.number.isRequired
}

/**
 * CreditCardStep - stateless presentational component with view of credit card information form
 * @param {object} props - props
 * @return {object} An object of children
 */
const CreditCardStep = () => {
  const { auth } = useUserContext()

  const { playCreditCardStepEnter, fadeIn } = useAnimationContext()
  const { isLoaded, formik } = useCreditCardStepContext()
  const { plan } = formik.values

  const isFreePlan = FREE_PLANS.includes(plan.value)

  useEffect(() => {
    if (isLoaded) {
      playCreditCardStepEnter()
    }
  }, [isLoaded])

  return (
    <Suspense fallback={<>...</>}>
      {isLoaded && (
        <Column className={styles['credit-card-step-layout']}>
          <motion.div initial={{ opacity: 0 }} animate={fadeIn}>
            <Spacer space='regular' />

            {isFreePlan ? (
              <FreePlanHeader />
            ) : (
              <PaidPlanHeader freeTrialDays={auth.free_trial_days} />
            )}

            <Spacer space='mini' />

            <Spacer space='tiny' />

            <CreditCardStepForm />

            <Spacer space='mini' />

            <Spacer space='tiny' />
          </motion.div>
        </Column>
      )}
    </Suspense>
  )
}

CreditCardStep.displayName = 'CreditCardStep Layout'

export default CreditCardStep
