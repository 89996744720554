import { useStyles } from '@hooks/useStyles'
import React, { FC, InputHTMLAttributes, ReactNode } from 'react'

import styles from './Radio.module.scss'

export interface RadioProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'className' | 'type' | 'size'
  > {
  field: {
    name: string
    value?: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
  }
  id: string
  label?: ReactNode
  className?: string | string[]
  disabled?: boolean
}

export const Radio: FC<RadioProps> = ({
  field: { name, value, onChange },
  id,
  label = '',
  className = '',
  disabled = false
}) => {
  const radioStyles = useStyles(
    {
      [styles.radio]: true
    },
    className
  )

  const labelStyles = useStyles(
    {
      [styles['label--disabled']]: disabled
    },
    className
  )

  return (
    <label className={radioStyles}>
      {label && (
        <label className={labelStyles} htmlFor={id}>
          {label}
        </label>
      )}

      <input
        name={name}
        id={id}
        type='radio'
        value={id}
        checked={id === value}
        onChange={onChange}
        className={radioStyles}
        disabled={disabled}
      />

      <div className={styles.radio__overlay} />
    </label>
  )
}

Radio.displayName = 'Radio'
