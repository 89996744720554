import { KeyboardKey } from '@constants/keyboardKey'
import { convertToLongUppercaseHex, isValidHexColor } from '@helpers/color'
import { useStyles } from '@hooks/useStyles'
import { Spreader } from '@ui-kit/Spreader'
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { Input, InputProps } from '../Input'
import styles from './HexInput.module.scss'

interface IHexInputProps extends Omit<InputProps, 'variant' | 'i18n'> {
  /**
   * Value of the input in hex format
   */
  value: string
  onConfirm: (value: string) => void
}

export const HexInput = ({
  className = '',
  value,
  size = 14,
  id,
  onConfirm,
  ...restProps
}: IHexInputProps) => {
  if (!isValidHexColor(value)) {
    throw new Error(`Invalid hex color value: ${value} passed to <HexInput />`)
  }

  const [renderedValue, setRenderedValue] = useState(value)
  const lastValidValue = useRef(value)

  const hexInputStyles = useStyles(
    {
      [styles.hexInput]: true
    },
    className
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const nextValue = event.target.value
      setRenderedValue(nextValue)

      if (isValidHexColor(nextValue)) {
        lastValidValue.current = convertToLongUppercaseHex(nextValue)
      }
    },
    []
  )

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      const { key } = event

      if (key === KeyboardKey.Enter) {
        setRenderedValue(lastValidValue.current)
        onConfirm(lastValidValue.current)
      }
    },
    [onConfirm]
  )

  const handleBlur = useCallback(() => {
    setRenderedValue(lastValidValue.current)
    onConfirm(lastValidValue.current)
  }, [onConfirm])

  useEffect(() => {
    setRenderedValue(value)
    lastValidValue.current = value
  }, [value])

  return (
    <label className={hexInputStyles}>
      <Input
        {...restProps}
        id={id}
        size={size}
        value={renderedValue}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={!isValidHexColor(renderedValue)}
      />
    </label>
  )
}

HexInput.displayName = 'HexInput'
