import { useStyles } from '@hooks/useStyles'
import { Icon } from '@ui-kit/Icon'
import { FC } from 'react'

import styles from './StatusIcon.module.scss'

export interface StatusIconProps {
  className?: string | string[]
  variant?: 'active' | 'error' | 'in-progress' | 'inactive' | 'warning'
  size?: 15 | 20 | 25
  'data-testid'?: string
}

export const StatusIcon: FC<StatusIconProps> = ({
  className = '',
  variant = 'active',
  size = 15,
  'data-testid': dataTestId = 'status-icon'
}) => {
  const statusIconStyles = useStyles(
    {
      [styles['status-icon']]: true,
      [styles[`status-icon--${variant}`]]: variant,
      [styles[`status-icon--size-${size}`]]: size
    },
    className
  )

  const iconStyles = useStyles({
    [styles['status-icon__icon']]: true,
    [styles[`status-icon__icon--${variant}`]]: variant
  })

  const variantsIcons = {
    active: {
      icon: 'icon-check-solid',
      color: 'white'
    },
    error: {
      icon: 'icon-exclamation-triangle',
      color: 'white'
    },
    warning: {
      icon: 'icon-exclamation',
      color: 'white'
    },
    'in-progress': {
      icon: 'icon-spinner',
      color: 'neutral-6'
    },
    inactive: {
      icon: 'icon-remove',
      color: 'white'
    }
  } as const

  const iconSizes = {
    15: 8,
    20: 12,
    25: 12
  } as const

  const iconSize = variant === 'in-progress' ? size : iconSizes[size]

  return (
    <div className={statusIconStyles} data-testid={dataTestId}>
      <Icon
        icon={variantsIcons[variant].icon}
        color={variantsIcons[variant].color}
        size={iconSize}
        spin={variant === 'in-progress'}
        className={iconStyles}
      />
    </div>
  )
}

StatusIcon.displayName = 'StatusIcon'
