export const useShowMagnifier = () => {
  const showMagnifier = (
    pageX: number,
    pageY: number,
    screenShotCanvas: HTMLCanvasElement
  ) => {
    // Needed to handle different screen pixel densities, e.g. on retina displays
    const devicePixelRatio = window.devicePixelRatio || 1

    const magnifierSize = 90
    const magnifierPixelsDimension = 15
    const magnifierPixelSize = magnifierSize / magnifierPixelsDimension

    const createMagnifierCanvas = () => {
      const magnifierCanvas = document.createElement('canvas')
      magnifierCanvas.width = magnifierSize * devicePixelRatio
      magnifierCanvas.height = magnifierSize * devicePixelRatio
      magnifierCanvas.id = 'magnifier'

      magnifierCanvas.style.width = `${magnifierSize}px`
      magnifierCanvas.style.height = `${magnifierSize}px`
      magnifierCanvas.style.position = 'absolute'
      magnifierCanvas.style.background = 'black'
      magnifierCanvas.style.borderRadius = '50%'
      magnifierCanvas.style.zIndex = '9999998'

      const magnifierCanvasInitTop = pageY - magnifierSize * 0.5
      const magnifierCanvasInitLeft = pageX - magnifierSize * 0.5
      magnifierCanvas.style.left = `${magnifierCanvasInitLeft}px`
      magnifierCanvas.style.top = `${magnifierCanvasInitTop}px`

      return {
        magnifierCanvas,
        magnifierCanvasInitTop,
        magnifierCanvasInitLeft
      }
    }

    const calculateSelectedPixelPointerDivPosition = (
      magnifierCanvasPosition: number
    ) => {
      return (
        magnifierCanvasPosition +
        magnifierPixelSize * Math.floor(magnifierPixelsDimension * 0.5)
      )
    }

    const createSelectedPixelPointerDiv = (
      magnifierCanvasTop: number,
      magnifierCanvasLeft: number
    ) => {
      const selectedPixelPointerDiv = document.createElement('div')
      selectedPixelPointerDiv.style.width = `${magnifierPixelSize}px`
      selectedPixelPointerDiv.style.height = `${magnifierPixelSize}px`
      selectedPixelPointerDiv.style.border = '1px solid black'
      selectedPixelPointerDiv.style.position = 'absolute'
      selectedPixelPointerDiv.style.top = `${calculateSelectedPixelPointerDivPosition(
        magnifierCanvasTop
      )}px`
      selectedPixelPointerDiv.style.left = `${calculateSelectedPixelPointerDivPosition(
        magnifierCanvasLeft
      )}px`
      selectedPixelPointerDiv.style.boxSizing = 'border-box'
      selectedPixelPointerDiv.style.zIndex = '9999999'

      return selectedPixelPointerDiv
    }

    const drawZoomedScreenshotInsideMagnifier = (
      screenshotX: number,
      screenshotY: number,
      magnifierCtx?: CanvasRenderingContext2D
    ) => {
      if (magnifierCtx) {
        magnifierCtx.drawImage(
          screenShotCanvas,
          (screenshotX - Math.floor(magnifierPixelsDimension * 0.5)) *
            devicePixelRatio,
          (screenshotY - Math.floor(magnifierPixelsDimension * 0.5)) *
            devicePixelRatio,
          magnifierPixelsDimension * devicePixelRatio, // render 15x15 screenshot canvas pixels...
          magnifierPixelsDimension * devicePixelRatio,
          0,
          0,
          magnifierSize, // ...on 90x90 magnifier canvas
          magnifierSize
        )
      }
    }

    const drawInitMagnifierImage = (
      magnifierCtx: CanvasRenderingContext2D | null
    ) => {
      if (magnifierCtx) {
        magnifierCtx.imageSmoothingEnabled = false
        magnifierCtx.scale(devicePixelRatio, devicePixelRatio)
        drawZoomedScreenshotInsideMagnifier(pageX, pageY, magnifierCtx)
      }
    }

    const { magnifierCanvas, magnifierCanvasInitTop, magnifierCanvasInitLeft } =
      createMagnifierCanvas()
    const magnifierContext = magnifierCanvas.getContext('2d')
    const selectedPixelPointerDiv = createSelectedPixelPointerDiv(
      magnifierCanvasInitTop,
      magnifierCanvasInitLeft
    )

    drawInitMagnifierImage(magnifierContext)

    const updateMagnifierCanvas = (e: MouseEvent) => {
      const magnifierCanvasTop = e.clientY - magnifierSize * 0.5
      const magnifierCanvasLeft = e.clientX - magnifierSize * 0.5

      magnifierCanvas.style.left = `${magnifierCanvasLeft}px`
      magnifierCanvas.style.top = `${magnifierCanvasTop}px`

      selectedPixelPointerDiv.style.top = `${calculateSelectedPixelPointerDivPosition(
        magnifierCanvasTop
      )}px`
      selectedPixelPointerDiv.style.left = `${calculateSelectedPixelPointerDivPosition(
        magnifierCanvasLeft
      )}px`

      if (magnifierContext) {
        drawZoomedScreenshotInsideMagnifier(
          e.clientX,
          e.clientY,
          magnifierContext
        )
      }
    }

    const handleHideMagnifier = () => {
      selectedPixelPointerDiv.removeEventListener('click', handleHideMagnifier)
      document.body.removeChild(magnifierCanvas)
      document.body.removeChild(selectedPixelPointerDiv)
      document.removeEventListener('mousemove', updateMagnifierCanvas)
    }

    // It is the pixel pointer that catches the click event, since it is on top of the magnifier
    selectedPixelPointerDiv.addEventListener('click', handleHideMagnifier)

    document.body.appendChild(selectedPixelPointerDiv)
    document.body.appendChild(magnifierCanvas)

    document.addEventListener('mousemove', updateMagnifierCanvas)
  }

  return {
    showMagnifier
  }
}
