import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import useSteps from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/useSteps'
import { Button, Heading, Input, Paragraph, Spacer } from '@ui-kit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

const FirstStep: React.FC = () => {
  const { t } = useTranslation()
  const { formik, validators } = useWizardContext()
  const { values, handleChange } = formik
  const { websiteName } = values
  const { isFirstStepValid } = validators
  const { nextStep } = useSteps()

  return (
    <div>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.is.your.site.called')}
      </Heading>
      <Spacer space={20} />
      <Paragraph size={14} color='neutral-6'>
        {t('landings.create.wizard.landing.modal.dont.stress.you.can.change')}
      </Paragraph>
      <Spacer space={20} />
      <Input
        size={16}
        value={websiteName}
        onChange={handleChange}
        id='websiteName'
        i18n={{ label: t('landings.create.wizard.landing.modal.website.name') }}
      />
      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button
          icon='icon-arrow-right'
          disabled={!isFirstStepValid}
          onClick={nextStep}
        >
          Next
        </Button>
      </Row>
    </div>
  )
}

export default FirstStep
