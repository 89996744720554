import { Row } from 'simple-flexbox'

import styles from './StyleBox.module.scss'

export const Palette = ({ colors }: { colors: string[][] }) => {
  return (
    <Row className={styles.pallete}>
      {colors?.map(color => (
        <div
          key={color[2]}
          className={styles.palleteItem}
          style={{
            backgroundColor: color[2]
          }}
        />
      ))}
    </Row>
  )
}
