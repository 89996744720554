import { RGBColor } from '@ui-kit/types/color'
import mix, { MixObject } from 'mix-css-color'

export const calculateColorBrightness = (color: RGBColor) => {
  const { r, g, b } = color
  return (100 * (r + g + b)) / (255 * 3)
}

export const convertRgbaColorToString = (color: RGBColor) => {
  const { r, g, b, a } = color
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

export enum ColorTintsVariant {
  adjusted,
  all
}

export const calculateColorTints = (
  baseColor: RGBColor,
  colorTintsVariant: ColorTintsVariant = ColorTintsVariant.adjusted
) => {
  const rgbaString = convertRgbaColorToString(baseColor)
  const weight = 25
  const tintsAndShadesAmount = 4
  const white = '#fff'
  const black = '#000'

  const colorObjectToRgba = (colorObj: MixObject) => ({
    r: colorObj.rgba[0],
    g: colorObj.rgba[1],
    b: colorObj.rgba[2],
    a: colorObj.rgba[3]
  })

  const mixWith = (colorString: string, colorIndex: number) => {
    const mixPercentage = (colorIndex + 1) * weight
    return mix(colorString, rgbaString, mixPercentage)
  }

  const shades = Array.from({ length: tintsAndShadesAmount }, (_, colorIndex) =>
    mixWith(black, colorIndex)
  ).map(colorObjectToRgba)

  const tints = Array.from({ length: tintsAndShadesAmount }, (_, colorIndex) =>
    mixWith(white, colorIndex)
  ).map(colorObjectToRgba)

  const allTints = [...tints.reverse(), { ...baseColor }, ...shades]

  if (colorTintsVariant === ColorTintsVariant.all) {
    return allTints
  }

  const extractFiveAdjustedColorTints = (colorTints: RGBColor[]) => {
    const baseColorBrightness = calculateColorBrightness(baseColor)

    const startColorIndex = Math.min(
      Math.floor(baseColorBrightness / 20),
      tintsAndShadesAmount
    )
    const endColorIndex = startColorIndex + 5
    return colorTints.slice(startColorIndex, endColorIndex)
  }

  return extractFiveAdjustedColorTints(allTints)
}

export const convertRgbaToHex = (
  color: RGBColor,
  options: { includeAlpha: boolean } = { includeAlpha: false }
): string => {
  // Ensure the values are within the valid range (0-255 for RGB, 0-1 for alpha)
  const r = Math.max(0, Math.min(255, color.r))
  const g = Math.max(0, Math.min(255, color.g))
  const b = Math.max(0, Math.min(255, color.b))
  const a = Math.max(0, Math.min(1, color.a))

  // Convert the RGB values to hex
  const hexR = r.toString(16).padStart(2, '0')
  const hexG = g.toString(16).padStart(2, '0')
  const hexB = b.toString(16).padStart(2, '0')

  // Convert the alpha value to a percentage and then to hex
  let hexA = ''
  if (options.includeAlpha) {
    const alphaPercentage = Math.round(a * 100)
    hexA = Math.round(alphaPercentage * (255 / 100))
      .toString(16)
      .padStart(2, '0')
  }

  return `#${hexR}${hexG}${hexB}${hexA}`.toUpperCase()
}

export const isValidHexColor = (hex: string) => {
  const HEX_REGEX = /^#?([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
  return HEX_REGEX.test(hex)
}

export const convertToLongUppercaseHex = (hexColor: string) => {
  if (!isValidHexColor(hexColor)) {
    throw new Error('Invalid hex color passed to convertToLongUppercaseHex')
  }

  const hex = hexColor.replace(/^#/, '')

  // If the hex code is a short format, convert it to a long format
  const longHex =
    hex.length === 3
      ? `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
      : hex

  return `#${longHex}`.toUpperCase()
}

export const convertHexColorToRgba = (hexColor: string): RGBColor => {
  if (!isValidHexColor(hexColor)) {
    throw new Error('Invalid hex color passed to convertHexColorToRgba')
  }

  const normalizedHex = convertToLongUppercaseHex(hexColor).replace(/^#/, '')

  const r = parseInt(normalizedHex.substring(0, 2), 16)
  const g = parseInt(normalizedHex.substring(2, 4), 16)
  const b = parseInt(normalizedHex.substring(4, 6), 16)

  return {
    r,
    g,
    b,
    a: 1
  }
}
