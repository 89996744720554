import { useStyles } from '@hooks/useStyles'
import { Icon } from '@ui-kit/Icon'
import { Panel } from '@ui-kit/Panel'
import { Paragraph } from '@ui-kit/Paragraph'
import { Popover } from '@ui-kit/Popover'
import { ProgressBar } from '@ui-kit/ProgressBar'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment, ReactNode } from 'react'
import { Row } from 'simple-flexbox'

import styles from './StatsProgressBar.module.scss'

export interface StatsProgressBarProps {
  className?: string | string[]
  nameOfItem: string
  icon?: string
  quantity: number
  numericValue: number | string
  action?: ReactNode
  stats: { label: string; value?: string | number; percentage: string }[]
  footerForPopover?: ReactNode
  additionalContent?: string
  'data-testid'?: string
}

export const StatsProgressBar: FC<StatsProgressBarProps> = ({
  className = '',
  icon,
  nameOfItem,
  numericValue,
  quantity,
  action,
  stats,
  footerForPopover,
  additionalContent,
  'data-testid': dataTestId = 'statsProgressBar'
}) => {
  const statsProgressBar = useStyles(
    {
      [styles.statsProgressBar]: true
    },
    className
  )

  const popover = useStyles({
    [styles.popover]: true
  })

  const percentageValue = `${quantity}%`

  const MAX_LABEL_LENGTH = 65

  const trimmedNameOfItem =
    nameOfItem.length > MAX_LABEL_LENGTH
      ? `${nameOfItem.slice(0, MAX_LABEL_LENGTH).trim()}..."`
      : nameOfItem

  const content = (
    <div className={popover}>
      <Panel>
        <Paragraph
          color='neutral-6'
          size={12}
          className={styles.popover__header}
        >
          {nameOfItem}
        </Paragraph>

        {additionalContent && (
          <Paragraph color='neutral-4' size={12}>
            {additionalContent}
          </Paragraph>
        )}

        <Spacer space={5} />

        {stats.map(({ label, value, percentage }, index) => (
          <Fragment key={label}>
            <div className={styles.popover__row}>
              <Paragraph>{label}</Paragraph>

              <Row>
                <Paragraph weight={600}>
                  {value != null ? value : percentage}
                </Paragraph>

                <Spreader spread={5} />

                {value != null && percentage != null && (
                  <Paragraph color='neutral-4'>({percentage})</Paragraph>
                )}
              </Row>
            </div>

            {index !== stats.length - 1 && <Spacer space={5} />}
          </Fragment>
        ))}
      </Panel>

      {footerForPopover && footerForPopover}
    </div>
  )

  return (
    <div className={statsProgressBar} data-testid={dataTestId}>
      <div className={styles.statsProgressBar__content}>
        <Popover surface={3} content={content} padding={0} placement='top'>
          <Row justifyContent='space-between' alignItems='center'>
            <Row alignItems='center'>
              {icon && (
                <Fragment>
                  <Icon icon={icon} size={14} />

                  <Spreader spread={10} />
                </Fragment>
              )}

              <Paragraph>{trimmedNameOfItem}</Paragraph>
            </Row>

            <Row alignItems='center'>
              <Paragraph weight={600} size={12}>
                {percentageValue}
              </Paragraph>

              <Spreader spread={5} />

              <Paragraph size={12} color='neutral-6'>
                ({numericValue})
              </Paragraph>
            </Row>
          </Row>

          <Spacer space={5} />

          <ProgressBar variant='progress' quantity={quantity} />
        </Popover>
      </div>

      {action && (
        <Fragment>
          <Spreader spread={10} />

          {action}
        </Fragment>
      )}
    </div>
  )
}

StatsProgressBar.displayName = 'StatsProgressBar'
