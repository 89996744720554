import { useNavigate } from 'react-router-dom'

export const useHistoryBack = (fallbackUrl: string) => {
  const navigate = useNavigate()

  // go back when source route is from SPA
  return () => {
    if (window.history.state) {
      navigate(-1)
    } else {
      navigate(fallbackUrl, { replace: true })
    }
  }
}
