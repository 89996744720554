import { Hint, Spacer } from '@ui-kit'
import React, { useMemo } from 'react'
import { Column } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import useSteps from '../helpers/useSteps'
import { SecionPreview } from './SectionPreview'

interface SecondColumnContentProps {}

const hints = [
  [
    'Naming your website enhances your ability to manage it 1',
    'Naming your website enhances your ability to manage it 2',
    'Naming your website enhances your ability to manage it 3'
  ],
  [
    'jestem podpowiedzia do drugiego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do drugiego pytania, wylosowano opcje numer 2'
  ],
  [
    'jestem podpowiedzia do trzeciego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do trzeciego pytania, wylosowano opcje numer 2'
  ],
  [
    'jestem podpowiedzia do czwartego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do czwartego pytania, wylosowano opcje numer 2'
  ],
  [
    'jestem podpowiedzia do piatego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do piatego pytania, wylosowano opcje numer 2'
  ],
  [
    'jestem podpowiedzia do szostego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do szostego pytania, wylosowano opcje numer 2'
  ]
]

const SecondColumnContent: React.FC<SecondColumnContentProps> = () => {
  const { stepNumber } = useSteps()
  const { selectedHeroSection } = useWizardContext()
  const hintText = useMemo(
    () =>
      hints[stepNumber - 1]?.[
        Math.floor(Math.random() * hints[stepNumber - 1].length)
      ],
    [stepNumber]
  )

  return (
    <Column>
      {selectedHeroSection && <SecionPreview id={selectedHeroSection} />}

      <Spacer space={10} />

      <Hint>{hintText}</Hint>
    </Column>
  )
}

export default SecondColumnContent
