import companyPresentation from '@images/wizard/goals/companyPresentation.png'
import contestSignup from '@images/wizard/goals/contestSignup.png'
import eventSignup from '@images/wizard/goals/eventSignup.png'
import mobileAppPromotion from '@images/wizard/goals/mobileAppPromotion.png'
import newsletterSignup from '@images/wizard/goals/newsletterSignup.png'
import offerDownload from '@images/wizard/goals/offerDownload.png'
import products from '@images/wizard/goals/products.png'
import productSale from '@images/wizard/goals/productSale.png'
import recruitment from '@images/wizard/goals/recruitment.png'
import resourceDownload from '@images/wizard/goals/resourceDownload.png'
import saasRegistration from '@images/wizard/goals/saasRegistration.png'
import scheduleMeeting from '@images/wizard/goals/scheduleMeeting.png'
import services from '@images/wizard/goals/services.png'
import serviceSale from '@images/wizard/goals/serviceSale.png'
import waitlist from '@images/wizard/goals/waitlist.png'
import webinarSignup from '@images/wizard/goals/webinarSignup.png'

import {
  COMPANY_PRESENTATION,
  CONTEST_SIGNUP,
  EVENT_SIGNUP,
  MOBILE_APP_PROMOTION,
  NEWSLETTER_SIGNUP,
  OFFER_DOWNLOAD,
  PRODUCT_SALE,
  RECRUITMENT,
  RESOURCE_DOWNLOAD,
  SAAS_REGISTRATION,
  SCHEDULE_MEETING,
  SERVICE_SALE,
  WAITLIST,
  WEBINAR_SIGNUP
} from '../constants/lpGoals'

export const secondStepOptions = [
  { name: COMPANY_PRESENTATION, image: companyPresentation },
  { name: CONTEST_SIGNUP, image: contestSignup },
  { name: EVENT_SIGNUP, image: eventSignup },
  { name: MOBILE_APP_PROMOTION, image: mobileAppPromotion },
  { name: NEWSLETTER_SIGNUP, image: newsletterSignup },
  { name: OFFER_DOWNLOAD, image: offerDownload },
  { name: PRODUCT_SALE, image: productSale },
  { name: RECRUITMENT, image: recruitment },
  { name: RESOURCE_DOWNLOAD, image: resourceDownload },
  { name: SAAS_REGISTRATION, image: saasRegistration },
  { name: SCHEDULE_MEETING, image: scheduleMeeting },
  { name: SERVICE_SALE, image: serviceSale },
  { name: WAITLIST, image: waitlist },
  { name: WEBINAR_SIGNUP, image: webinarSignup }
] as const

export const thirdStepOptions = [
  { name: 'products', image: products },
  { name: 'services', image: services }
]

export const optionsWithAccessToThirdStep = [
  COMPANY_PRESENTATION,
  WAITLIST,
  OFFER_DOWNLOAD
]

export const pallets = {
  modern: [
    {
      colors: [
        ['#CDB3FC', '#B48DFA', '#6D20F7', '#4915A5', '#240B52'],
        ['#FCF3B9', '#F8EB95', '#F5DB31', '#A39221', '#524910'],
        ['#EBEBEB', '#BCBCBC', '#8D8D8D', '#5E5E5E', '#2F2F2F']
      ],
      font: 'Lato'
    },
    {
      colors: [
        ['#F9D0C1', '#F2B6A1', '#EC774D', '#9D4F33', '#4F281A'],
        ['#CCDAFA', '#9AB7F5', '#7389B8', '#4D5C7A', '#262E3D'],
        ['#F6F2F0', '#C5C2C0', '#949190', '#626160', '#313030']
      ],

      font: 'Exo'
    },
    {
      colors: [
        ['#EEBECF', '#DC9BB1', '#CB527C', '#873753', '#441B29'],
        ['#BCD2D9', '#95ABB2', '#6D848C', '#445D65', '#15353F'],
        ['#FEFEF4', '#CBCBC3', '#989892', '#666662', '#333331']
      ],
      font: 'Oswald'
    }
  ],
  dark: [
    {
      colors: [
        ['#CDB3FC', '#B48DFA', '#6D20F7', '#4915A5', '#240B52'],
        ['#FCF3B9', '#F8EB95', '#F5DB31', '#A39221', '#524910'],
        ['#EBEBEB', '#BCBCBC', '#8D8D8D', '#5E5E5E', '#2F2F2F']
      ],
      font: 'Lato'
    },
    {
      colors: [
        ['#F9D0C1', '#F2B6A1', '#EC774D', '#9D4F33', '#4F281A'],
        ['#CCDAFA', '#9AB7F5', '#7389B8', '#4D5C7A', '#262E3D'],
        ['#F6F2F0', '#C5C2C0', '#949190', '#626160', '#313030']
      ],
      font: 'Exo'
    },
    {
      colors: [
        ['#EEBECF', '#DC9BB1', '#CB527C', '#873753', '#441B29'],
        ['#BCD2D9', '#95ABB2', '#6D848C', '#445D65', '#15353F'],
        ['#FEFEF4', '#CBCBC3', '#989892', '#666662', '#333331']
      ],
      font: 'Oswald'
    }
  ],
  elegant: [
    {
      colors: [
        ['#CDB3FC', '#B48DFA', '#6D20F7', '#4915A5', '#240B52'],
        ['#FCF3B9', '#F8EB95', '#F5DB31', '#A39221', '#524910'],
        ['#EBEBEB', '#BCBCBC', '#8D8D8D', '#5E5E5E', '#2F2F2F']
      ],
      font: 'Lato'
    },
    {
      colors: [
        ['#F9D0C1', '#F2B6A1', '#EC774D', '#9D4F33', '#4F281A'],
        ['#CCDAFA', '#9AB7F5', '#7389B8', '#4D5C7A', '#262E3D'],
        ['#F6F2F0', '#C5C2C0', '#949190', '#626160', '#313030']
      ],
      font: 'Exo'
    },
    {
      colors: [
        ['#EEBECF', '#DC9BB1', '#CB527C', '#873753', '#441B29'],
        ['#BCD2D9', '#95ABB2', '#6D848C', '#445D65', '#15353F'],
        ['#FEFEF4', '#CBCBC3', '#989892', '#666662', '#333331']
      ],
      font: 'Oswald'
    }
  ],
  minimal: [
    {
      colors: [
        ['#CDB3FC', '#B48DFA', '#6D20F7', '#4915A5', '#240B52'],
        ['#FCF3B9', '#F8EB95', '#F5DB31', '#A39221', '#524910'],
        ['#EBEBEB', '#BCBCBC', '#8D8D8D', '#5E5E5E', '#2F2F2F']
      ],
      font: 'Lato'
    },
    {
      colors: [
        ['#F9D0C1', '#F2B6A1', '#EC774D', '#9D4F33', '#4F281A'],
        ['#CCDAFA', '#9AB7F5', '#7389B8', '#4D5C7A', '#262E3D'],
        ['#F6F2F0', '#C5C2C0', '#949190', '#626160', '#313030']
      ],
      font: 'Exo'
    },
    {
      colors: [
        ['#EEBECF', '#DC9BB1', '#CB527C', '#873753', '#441B29'],
        ['#BCD2D9', '#95ABB2', '#6D848C', '#445D65', '#15353F'],
        ['#FEFEF4', '#CBCBC3', '#989892', '#666662', '#333331']
      ],
      font: 'Oswald'
    }
  ]
}
