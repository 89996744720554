import { WorkspacesContent } from '@components/Sidebar/components/WorkspacesContent'
import { useSidebarContext } from '@contexts/sidebar'
import { Backdrop, Button, Heading, Icon } from '@landingi/landingi-ui-kit'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Workspaces.module.scss'

const animate = {
  close: { x: -340 },
  open: { x: 0 }
}

export const Workspaces = () => {
  const { t } = useTranslation()

  const { isWorkspacesOpen, toggleWorkspacesOpen } = useSidebarContext()

  return (
    <Fragment>
      <AnimatePresence>
        {isWorkspacesOpen && (
          <motion.div
            key='workspaces'
            className={styles.workspaces}
            initial={animate.close}
            animate={animate.open}
            exit={animate.close}
            transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
          >
            <div className={styles.workspaces__wrapper}>
              <Row
                justifyContent='space-between'
                className={styles.workspaces__top}
              >
                <Heading level={2} margin='none'>
                  {t('account.subaccounts.label')}
                </Heading>

                <Button
                  variant='icon'
                  size='tiny'
                  onClick={toggleWorkspacesOpen}
                >
                  <Icon icon='icon-remove' />
                </Button>
              </Row>

              <WorkspacesContent />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {isWorkspacesOpen ? (
        <Backdrop onClick={toggleWorkspacesOpen} zIndex='6' />
      ) : null}
    </Fragment>
  )
}

Workspaces.displayName = 'SidebarWorkspaces'
