import { useFeatureFlags } from '@contexts/featureFlags'
import { lazy } from '@helpers/lazy'
import { NotFound } from '@pages/NotFound/routes/NotFound'
import { LANDINGS } from '@routes/path'
import { useGetAccountInfo } from '@services/account'

const Overview = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/Overview').then(
    ({ Overview }) => ({ default: Overview })
  )
)

const JavascriptCode = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/JavascriptCode').then(
    ({ JavascriptCode }) => ({ default: JavascriptCode })
  )
)

const JavascriptCodeAddEdit = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/JavascriptCodeAddEdit').then(
    ({ JavascriptCodeAddEdit }) => ({
      default: JavascriptCodeAddEdit
    })
  )
)

const Infobar = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/Infobar').then(
    ({ Infobar }) => ({ default: Infobar })
  )
)

const Schedule = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/Schedule').then(
    ({ Schedule }) => ({ default: Schedule })
  )
)

const Settings = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/Settings').then(
    ({ Settings }) => ({ default: Settings })
  )
)

const Optimization = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/Optimization').then(
    ({ Optimization }) => ({ default: Optimization })
  )
)

const EventsWrapper = lazy(() =>
  import('@pages/Landings/routes/Dashboard/routes/Events/EventsWrapper').then(
    ({ EventsWrapper }) => ({ default: EventsWrapper })
  )
)

const EventTrackerRoute = () => {
  const { isTypeClient, isTypeManager } = useGetAccountInfo()

  const hasAccessToEventTracker = useFeatureFlags('EVENT_TRACKER')

  const userHasNotAccessToEventTracker =
    (isTypeClient || isTypeManager) && !hasAccessToEventTracker

  const hasNotAccessToEventTrackerTab =
    !useFeatureFlags('EVENT_TRACKER_TAB') || userHasNotAccessToEventTracker

  return hasNotAccessToEventTrackerTab ? <NotFound /> : <EventsWrapper />
}

const routes = [
  {
    path: LANDINGS.LANDINGS_DASHBOARD,
    element: <Overview />
  },
  {
    path: LANDINGS.EVENT_TRACKER,
    element: <EventTrackerRoute />
  },
  { path: LANDINGS.LANDINGS_AB, element: <Optimization /> },
  { path: LANDINGS.LANDINGS_JAVASCRIPT, element: <JavascriptCode /> },
  {
    path: LANDINGS.LANDINGS_JAVASCRIPT_ADD,
    element: <JavascriptCodeAddEdit />
  },
  {
    path: LANDINGS.LANDINGS_JAVASCRIPT_EDIT,
    element: <JavascriptCodeAddEdit />
  },
  { path: LANDINGS.LANDINGS_INFO_BAR, element: <Infobar /> },
  { path: LANDINGS.LANDINGS_SETTINGS, element: <Settings /> },
  { path: LANDINGS.LANDINGS_SCHEDULE, element: <Schedule /> }
]

export const useRoutesPaths = () => {
  return [
    {
      path: LANDINGS.LANDINGS_DASHBOARD,
      title: 'word.overview'
    },
    {
      path: LANDINGS.EVENT_TRACKER,
      title: 'word.dashboard.events',
      isHidden: 'hasNotAccessToEventTrackerTab',
      className: 'overview-tab-tour-events'
    },
    { path: LANDINGS.LANDINGS_AB, title: 'word.optimization' },
    {
      path: LANDINGS.LANDINGS_JAVASCRIPT,
      title: 'landings.dashboard.tabs.javascript'
    },
    {
      path: LANDINGS.LANDINGS_INFO_BAR,
      title: 'landings.dashboard.tabs.infobar'
    },
    {
      path: LANDINGS.LANDINGS_SCHEDULE,
      title: 'word.schedule'
    },
    {
      path: LANDINGS.LANDINGS_SETTINGS,
      title: 'landings.dashboard.tabs.settings'
    }
  ]
}

export default routes
