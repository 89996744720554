import { LongLogo } from '@components/Logo'
import { Back, Paragraph, ProgressBar, Spacer } from '@landingi/landingi-ui-kit'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './HeaderElements.module.scss'

export const Logo = () => {
  return (
    <Row justifyContent='center' className={styles.logo__wrapper}>
      <LongLogo />
    </Row>
  )
}

Logo.displayName = 'Logo'

/**
 * StepsBar - component that renders paragraph with login call to action
 * @return {object} children
 */
export const StepsBar = () => {
  const { currentStep, maxSteps, previousStep } = useStepsContext()

  const { t } = useTranslation()

  return currentStep > 0 ? (
    <motion.div className={styles['steps-bar']}>
      {currentStep > 1 && (
        <Back className={styles.back} onClick={previousStep} />
      )}

      <LongLogo />

      <Column style={{ width: 120 }}>
        <ProgressBar
          variant='brand'
          quantity={currentStep}
          limit={maxSteps}
          size='small'
          limitText='word.active.subaccounts'
        />

        <Spacer space='tiny' />

        <Row justifyContent='end'>
          <Paragraph size={12} color='accent-2'>
            {t('registration.flow.survey.step')}
            {` ${currentStep}/${maxSteps}`}
          </Paragraph>
        </Row>
      </Column>
    </motion.div>
  ) : (
    <LongLogo />
  )
}

StepsBar.displayName = 'StepsBar'
