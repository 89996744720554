import { useStyles } from '@hooks/useStyles'
import {
  ErrorColor,
  NeutralColor,
  PrimaryColor,
  SuccessColor,
  WarningColor,
  WhiteColor
} from '@ui-kit/types/color'
import { FC, ReactNode } from 'react'

import styles from './Text.module.scss'

export type TextSize = 10 | 12 | 14 | 16 | 18
export type TextColor =
  | NeutralColor
  | WhiteColor
  | PrimaryColor
  | SuccessColor
  | WarningColor
  | ErrorColor

export interface TextProps {
  children: ReactNode
  className?: string | string[]
  size?: TextSize
  color?: TextColor
  weight?: 300 | 400 | 600
  align?: 'left' | 'center' | 'right' | 'justify'
  'data-testid'?: string
  tag?: 'p' | 'span'
}

export const Text: FC<TextProps> = ({
  children,
  className,
  size = undefined,
  color = 'neutral',
  weight = 400,
  align = 'left',
  tag = 'span',
  'data-testid': dataTestId = 'text'
}) => {
  const textStyles = useStyles(
    {
      [styles.text]: true,
      [styles[`text--size-${size}`]]: size,
      [styles[`text--color-${color}`]]: color,
      [styles[`text--weight-${weight}`]]: weight,
      [styles[`text--align-${align}`]]: align
    },
    className
  )

  const Tag = tag

  return (
    <Tag data-testid={dataTestId} className={textStyles}>
      {children}
    </Tag>
  )
}

Text.displayName = 'Text'
