import {
  modern_01,
  modern_02,
  modern_03,
  modern_04,
  modern_05,
  modern_06,
  modern_07,
  modern_08,
  modern_09,
  modern_10,
  modern_11,
  modern_12,
  modern_13,
  modern_14,
  modern_15
} from './modern'

export const getPreview = ({
  id,
  data
}: {
  id: string
  data: { colors: string[][]; font: string }
}) => {
  const colors = {
    primary: data.colors[0],
    secondary: data.colors[1],
    tertiary: data.colors[2]
  }

  const viewData = {
    colors,
    font: data.font
  }

  switch (id) {
    case 'modern_01':
      return modern_01(viewData)
    case 'modern_02':
      return modern_02(viewData)
    case 'modern_03':
      return modern_03(viewData)
    case 'modern_04':
      return modern_04(viewData)
    case 'modern_05':
      return modern_05(viewData)
    case 'modern_06':
      return modern_06(viewData)
    case 'modern_07':
      return modern_07(viewData)
    case 'modern_08':
      return modern_08(viewData)
    case 'modern_09':
      return modern_09(viewData)
    case 'modern_10':
      return modern_10(viewData)
    case 'modern_11':
      return modern_11(viewData)
    case 'modern_12':
      return modern_12(viewData)
    case 'modern_13':
      return modern_13(viewData)
    case 'modern_14':
      return modern_14(viewData)
    case 'modern_15':
      return modern_15(viewData)
    default:
      return null
  }
}
