import { useStyles } from '@hooks/useStyles'
import { Button } from '@ui-kit/Button'
import { useDialogContext } from '@ui-kit/Dialog/context'
import { Spreader } from '@ui-kit/Spreader'
import { FC, MouseEventHandler } from 'react'

import styles from './DialogActions.module.scss'

type ActionType = {
  i18nTitle: string
  onClick: MouseEventHandler
}

interface DialogActionsProps {
  primaryAction: ActionType
  secondaryAction?: ActionType
  variant?: 'default' | 'border-footer'
}

export const DialogActions: FC<DialogActionsProps> = ({
  primaryAction,
  secondaryAction,
  variant = 'default'
}) => {
  const { size, moveToDefaultPosition, moveToPlacementOnClose } =
    useDialogContext()

  const dialogActionsStyles = useStyles({
    [styles.dialog__actions]: true,
    [styles[`dialog__actions--space-${size}`]]: size,
    [styles[`dialog__actions--variant-${variant}`]]: variant
  })

  const handlePrimaryActionClick: MouseEventHandler = event => {
    primaryAction.onClick(event)

    if (moveToPlacementOnClose) {
      moveToDefaultPosition()
    }
  }

  const handleSecondaryActionClick: MouseEventHandler = event => {
    secondaryAction?.onClick(event)

    if (moveToPlacementOnClose) {
      moveToDefaultPosition()
    }
  }

  return (
    <div className={dialogActionsStyles}>
      {secondaryAction && (
        <Button
          data-testid='secondary-action-button'
          variant='text-neutral'
          size={size}
          onClick={handleSecondaryActionClick}
        >
          {secondaryAction.i18nTitle}
        </Button>
      )}

      <Spreader spread={10} />

      <Button
        data-testid='primary-action-button'
        variant='primary'
        size={size}
        onClick={handlePrimaryActionClick}
      >
        {primaryAction.i18nTitle}
      </Button>
    </div>
  )
}

DialogActions.displayName = 'DialogActions'
