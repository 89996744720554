import { useFormik } from 'formik'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { object, string } from 'yup'

import {
  COMPANY_PRESENTATION,
  CONTEST_SIGNUP,
  EVENT_SIGNUP,
  LpGoal,
  MOBILE_APP_PROMOTION,
  NEWSLETTER_SIGNUP,
  OFFER_DOWNLOAD,
  PRODUCT_SALE,
  RECRUITMENT,
  RESOURCE_DOWNLOAD,
  SAAS_REGISTRATION,
  SCHEDULE_MEETING,
  SERVICE_SALE,
  WAITLIST,
  WEBINAR_SIGNUP
} from '../constants/lpGoals'
import { pallets } from '../helpers/constants'
import {
  isSectionForGoal,
  randomizeHeroSection,
  SectionType
} from '../helpers/randomizeHeroSection'
import { LpStyle, Pallete } from '../types'

const initialValues: {
  websiteName: string
  topGoal?: LpGoal
  websiteOffer: string
  style?: LpStyle
  palette: Pallete
  topic?: string
  customPalette: {
    font: string
    colors: string[][]
    mainColors: string[]
  }
} = {
  websiteName: '',
  websiteOffer: '',
  style: undefined,
  palette: 0,
  topGoal: undefined,
  topic: undefined,
  customPalette: {
    font: '',
    colors: [],
    mainColors: []
  }
}

export type WizardProviderValue = {
  formik: ReturnType<typeof useFormik<typeof initialValues>>
  validators: {
    isFirstStepValid: boolean
    isSecondStepValid: boolean
    isThirdStepValid: boolean
    isFourthStepValid: boolean
    isSixthStepValid: boolean
  }
  setStyle: (style: LpStyle) => void
  setGoal: (goal: LpGoal) => void
  selectedHeroSection?: SectionType
}

const WizardContext = createContext<WizardProviderValue | null>(null)

export const WizardProvider = () => {
  const { t } = useTranslation()
  // every style must be saved so sections are not randomized again when switching between styles
  const [selectedHeroSections, setSelectedHeroSections] = useState<{
    modern?: SectionType
    elegant?: SectionType
    dark?: SectionType
    minimal?: SectionType
  }>({
    modern: undefined,
    elegant: undefined,
    dark: undefined,
    minimal: undefined
  })

  const FormSchema = object().shape({
    websiteName: string()
      .trim()
      .max(250)
      .required(t('form.validation.isRequired')),
    topGoal: string()
      .oneOf([
        COMPANY_PRESENTATION,
        CONTEST_SIGNUP,
        EVENT_SIGNUP,
        MOBILE_APP_PROMOTION,
        NEWSLETTER_SIGNUP,
        OFFER_DOWNLOAD,
        PRODUCT_SALE,
        RECRUITMENT,
        RESOURCE_DOWNLOAD,
        SAAS_REGISTRATION,
        SCHEDULE_MEETING,
        SERVICE_SALE,
        WAITLIST,
        WEBINAR_SIGNUP
      ])
      .required(),
    websiteOffer: string().oneOf(['products', 'services']).required(),
    style: string().oneOf(['modern', 'dark', 'elegant', 'minimal']).required(),
    topic: string().required()
  })

  const onSubmit = () => {
    // TODO
  }

  const formik = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit,
    validateOnMount: true
  })

  const updateHeroSections = useCallback((style: LpStyle, goal: LpGoal) => {
    const section = randomizeHeroSection(style, goal)

    setSelectedHeroSections(prev => ({
      ...prev,
      [style]: section
    }))
  }, [])

  const setStyle = useCallback(
    (style: LpStyle) => {
      formik.setFieldValue('palette', `0`)
      formik.setFieldValue('customPalette.font', pallets[style][0].font)
      formik.setFieldValue('customPalette.colors', pallets[style][0].colors)
      formik.setFieldValue('customPalette.mainColors', [
        pallets[style][0].colors[0][2],
        pallets[style][0].colors[1][2],
        pallets[style][0].colors[2][2]
      ])
      formik.setFieldValue('style', style)

      if (formik.values.topGoal && selectedHeroSections[style] === undefined) {
        updateHeroSections(style, formik.values.topGoal)
      }
    },
    [formik, updateHeroSections, selectedHeroSections]
  )

  const setGoal = useCallback(
    (goal: LpGoal) => {
      const previousSection =
        formik.values.style && selectedHeroSections[formik.values.style]

      formik.setFieldValue('topGoal', goal)

      if (
        formik.values.style &&
        !isSectionForGoal(formik.values.style, goal, previousSection)
      ) {
        updateHeroSections(formik.values.style, goal)
      }
    },
    [formik, updateHeroSections, selectedHeroSections]
  )

  const validators = useMemo(
    () => ({
      isFirstStepValid: formik.errors.websiteName === undefined,
      isSecondStepValid: formik.errors.topGoal === undefined,
      isThirdStepValid: formik.errors.websiteOffer === undefined,
      isFourthStepValid: formik.errors.style === undefined,
      isSixthStepValid: formik.errors.topic === undefined
    }),
    [formik.errors]
  )

  const ctxValue = useMemo(
    () => ({
      formik,
      validators,
      setStyle,
      setGoal,
      selectedHeroSection:
        formik.values.style && selectedHeroSections[formik.values.style]
    }),
    [formik, validators, setStyle, selectedHeroSections, setGoal]
  )

  return (
    <WizardContext.Provider value={ctxValue}>
      <Outlet />
    </WizardContext.Provider>
  )
}

export const useWizardContext = () => {
  const ctxValue = useContext(WizardContext)

  if (!ctxValue) {
    throw new Error('useWizardContext must be used inside WizardProvider')
  }

  return ctxValue
}
