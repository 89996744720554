import { useUserContext } from '@contexts/user'
import { calculateDaysBetweenDates, getDateString } from '@helpers/date'
import { useTranslation } from 'react-i18next'

import { AccountSuspended } from '../components/AccountSuspended'
import { FreeTrialDefaultMessage } from '../components/FreeTrialDefaultMessage'
import { HasSubscription } from '../components/HasSubscription/HasSubscription'
import { WillExpireInLessThan4Days } from '../components/WillExpireInLessThan4Days'
import { WillExpireToday } from '../components/WillExpireToday'
import { AlertTypes, INFOBAR_TYPE } from '../constants'

export const useGetAccountExpiresAtType = (): {
  type: AlertTypes
  component: () => JSX.Element
} | null => {
  const { t } = useTranslation()

  const {
    user,
    expiresAt,
    hasFreeTrial,
    isSubaccount,
    isFreePlan,
    hasSubscription
  } = useUserContext()

  const today = new Date()

  today.setHours(2)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)

  const expiresAtDate = expiresAt?.formatted
    ? new Date(expiresAt.formatted)
    : null

  const days = expiresAtDate && calculateDaysBetweenDates(expiresAtDate, today)

  const willExpireInLessThan4Days = days && days < 4

  const buttonContent = hasSubscription
    ? t('payments.start-your-plan-now')
    : t('payments.go-to-payments')

  const hasSubscriptionError = user.subscription.error

  if (
    isFreePlan ||
    isSubaccount ||
    (hasSubscription && !hasFreeTrial) ||
    expiresAt === null ||
    hasSubscriptionError
  ) {
    return null
  }

  if (expiresAtDate) {
    if (getDateString(expiresAtDate) < getDateString(today)) {
      return {
        type: INFOBAR_TYPE.ALERT,
        component: () => <AccountSuspended buttonContent={buttonContent} />
      }
    }

    if (getDateString(expiresAtDate) === getDateString(today)) {
      return {
        type: INFOBAR_TYPE.WARNING,
        component: () => <WillExpireToday buttonContent={buttonContent} />
      }
    }
  }

  if (hasFreeTrial && user.display_free_trial_infobar) {
    return {
      type: INFOBAR_TYPE.WARNING,
      component: () => {
        if (hasSubscription) {
          return <HasSubscription />
        }

        return <FreeTrialDefaultMessage />
      }
    }
  }

  if (expiresAtDate && willExpireInLessThan4Days) {
    return {
      type: INFOBAR_TYPE.WARNING,
      component: () => (
        <WillExpireInLessThan4Days days={days} buttonContent={buttonContent} />
      )
    }
  }

  return null
}
