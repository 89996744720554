import Loading from '@images/landings/wizard/loading.png'
import { Hint, Image, Paragraph, Spacer } from '@ui-kit'
import { Column, Row } from 'simple-flexbox'

import styles from './WizardGenerationLayout.module.scss'

export const WizardGenerationLayout = () => {
  return (
    <Row className={styles['wizard-survey-layout']} justifyContent='center'>
      <Row className={styles['wizard-survey-layout__content']}>
        <Column
          className={styles['wizard-survey-layout__first-column']}
          justifyContent='center'
        >
          <Hint>
            We`re generating your landing page, your website. This may take a
            few minutes. Please wait. Do not close this window. We will notify
            you when it`s ready.
          </Hint>

          <Spacer space={20} />

          <Hint isTip>
            You can always remove a section or change its order later in the
            editor, if you don`t like the result.
          </Hint>
        </Column>
        <Row className={styles['wizard-survey-layout__second-column']}>
          <Row
            className={styles['wizard-survey-layout__second-column-content']}
            alignItems='center'
            justifyContent='center'
          >
            <Column>
              <Image src={Loading} width='181px' height='181px' />
              <Paragraph color='neutral-6' size={16} align='center'>
                Preparing layout...
              </Paragraph>
            </Column>
          </Row>
        </Row>
      </Row>
    </Row>
  )
}
