import { Heading } from '@landingi/landingi-ui-kit'
import { StartTrialCTA } from '@pages/Authentication/components/RedirectedCTA'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { REGISTRATION } from '@routes/path'
import { Paragraph, Spacer } from '@ui-kit'
import { Suspense } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import styles from './ForgotPassword.module.scss'
import Form from './Form'

const ForgotPassword = () => {
  const { searchParams } = useRegisterContext()
  const { t } = useTranslation()

  return (
    <Suspense fallback={<>...</>}>
      <Column className={styles.layout}>
        <Heading level={1} bold align='center'>
          {t('registration.flow.reset.your.password', {
            days: 14
          })}
        </Heading>

        <Paragraph size={16} align='center'>
          {t('registration.flow.enter.your.account.email')}
        </Paragraph>

        <Spacer space={30} />

        <Form />

        <Spacer space={5} />

        <Spacer space={10} />

        <Paragraph size={12} align='center'>
          <Link to={`${REGISTRATION.LOGIN.DEFAULT}?${searchParams.toString()}`}>
            <Trans i18nKey='registration.flow.go.back.to.sign.in' />
          </Link>
        </Paragraph>

        <Spacer space={15} />

        <StartTrialCTA />
      </Column>
    </Suspense>
  )
}

export default ForgotPassword
