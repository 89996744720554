import { debounce } from '@helpers/events'
import { useStyles } from '@hooks/useStyles'
import { Button } from '@ui-kit/Button'
import { Spreader } from '@ui-kit/Spreader'
import { ChangeEvent, FC, useRef, useState } from 'react'
import { Row } from 'simple-flexbox'

import styles from './Search.module.scss'

export interface SearchProps {
  className?: string | string[]
  tag?: 'div' | 'form'
  size?: 12 | 14 | 16
  searchPhrase?: string
  setSearchPhrase: (searchPhrase: string) => void
  i18n?: {
    placeholder?: string
  }
  disabled?: boolean
  autoFocus?: boolean
  liveChanges?: boolean
}

export const Search: FC<SearchProps> = ({
  className,
  tag: Tag = 'form',
  size = 14,
  searchPhrase,
  setSearchPhrase,
  disabled = false,
  i18n = {},
  autoFocus = false,
  liveChanges = false
}) => {
  const searchStyles = useStyles(
    {
      [styles.wrapper]: true,
      [styles[`wrapper--disabled`]]: disabled,
      [styles[`wrapper-size--${size}`]]: true
    },
    className
  )

  const inputRef = useRef<HTMLInputElement>(null)

  const [inputValue, setInputValue] = useState(searchPhrase)

  const debounceSetSearchPhrase = debounce(
    (value: string) => setSearchPhrase(value),
    300
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)

    if (
      liveChanges &&
      (event.target.value.length >= 3 || event.target.value.length === 0)
    ) {
      debounceSetSearchPhrase(event.target.value)
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const value = inputRef.current?.value || ''
    setSearchPhrase(value)
  }

  const handleClean = () => {
    setInputValue('')
    setSearchPhrase('')
  }

  return (
    <Tag onSubmit={handleSubmit} data-testid='form'>
      <Row className={searchStyles} alignItems='center' data-testid='wrapper'>
        <Button
          variant='text-neutral'
          size={size}
          isSquare
          disabled={!inputValue || disabled}
          icon='icon-search'
          onClick={handleSubmit}
        />

        <Spreader spread={5} />

        <input
          type='text'
          placeholder={i18n.placeholder}
          value={inputValue}
          onChange={handleChange}
          ref={inputRef}
          disabled={disabled}
          autoFocus={autoFocus}
        />

        <Spreader spread={5} />

        {inputValue && (
          <Button
            variant='text-neutral'
            size={size}
            isSquare
            onClick={handleClean}
            disabled={disabled}
            icon='icon-remove'
            data-testid='clean-button'
          />
        )}
      </Row>
    </Tag>
  )
}

Search.displayName = 'Search'
