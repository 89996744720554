export const COMPANY_PRESENTATION = 'companyPresentation'
export const CONTEST_SIGNUP = 'contestSignup'
export const EVENT_SIGNUP = 'eventSignup'
export const MOBILE_APP_PROMOTION = 'mobileAppPromotion'
export const NEWSLETTER_SIGNUP = 'newsletterSignup'
export const OFFER_DOWNLOAD = 'offerDownload'
export const PRODUCT_SALE = 'productSale'
export const RECRUITMENT = 'recruitment'
export const RESOURCE_DOWNLOAD = 'resourceDownload'
export const SAAS_REGISTRATION = 'saasRegistration'
export const SCHEDULE_MEETING = 'scheduleMeeting'
export const SERVICE_SALE = 'serviceSale'
export const WAITLIST = 'waitlist'
export const WEBINAR_SIGNUP = 'webinarSignup'

export type LpGoal =
  | typeof COMPANY_PRESENTATION
  | typeof CONTEST_SIGNUP
  | typeof EVENT_SIGNUP
  | typeof MOBILE_APP_PROMOTION
  | typeof NEWSLETTER_SIGNUP
  | typeof OFFER_DOWNLOAD
  | typeof PRODUCT_SALE
  | typeof RECRUITMENT
  | typeof RESOURCE_DOWNLOAD
  | typeof SAAS_REGISTRATION
  | typeof SCHEDULE_MEETING
  | typeof SERVICE_SALE
  | typeof WAITLIST
  | typeof WEBINAR_SIGNUP
