import { ADDONS_IDS } from '@constants/addonsIds'
import ProgrammaticLP from '@images/addons/icons/ProgrammaticLP.svg'
import {
  FEATURE,
  NEW_NO_ICON,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { POC_TEST, PocTestAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getProgrammaticLPAddon: (
  t: TFunction<'translation', undefined>
) => PocTestAddonTemplate = t => ({
  type: POC_TEST,
  name: t('addons.store.programmatic.lp.name'),
  title: t('addons.store.programmatic.lp'),
  tileDescription: <Trans i18nKey='addons.store.programmatic.lp.description' />,
  pageDescription: t('addons.store.programmatic.lp.page.description'),
  badges: [NEW_NO_ICON, FEATURE, SEVEN_DAYS_FREE_TRIAL],
  imageSrc: ProgrammaticLP,
  id: ADDONS_IDS.PROGRAMMATIC_LP,
  uuid: 'TODO',
  overview: getOverview(t),
  features: getFeatures(),
  useCases: getUseCases(t),
  price: {
    1: {
      USD: 57,
      PLN: 199,
      GBP: 49,
      BRL: 249,
      EUR: 57
    }
  },
  pricing: getPricing(t),
  faq: getFAQ(t),
  isBeingTested: true
})
