import { setSignInDate } from '@services/account/setSignInDate'
import { useGetSignInDate } from '@services/account/useGetSignInDate'
import moment from 'moment-timezone'
import { mutate } from 'swr'

import { useLazyService } from './useLazyService'
import { useUpdateEffect } from './useUpdateEffect'

const PROMOTION_FOR_FREE_PLAN = 'PROMOTION_FOR_FREE_PLAN'

export const useCalcDatesForPromotion = () => {
  const [setSignInDateRequest, { isLoading: isLoadingSetDate }] =
    useLazyService(setSignInDate, {
      onSuccess: () => {
        mutate(`auth/sign-in-date/get-date?action=${PROMOTION_FOR_FREE_PLAN}`)
      }
    })

  const {
    data,
    isLoading: isLoadingGetDate,
    isValidating: isValidatingGetDate,
    error: errorGetDate
  } = useGetSignInDate({
    action: PROMOTION_FOR_FREE_PLAN
  })

  const isLoading = isLoadingSetDate || isLoadingGetDate || isValidatingGetDate

  const handleSetSignInDate = async () => {
    if (data && !data.date) {
      await setSignInDateRequest({ action: PROMOTION_FOR_FREE_PLAN })
    }
  }

  useUpdateEffect(() => {
    handleSetSignInDate()
  }, [data])

  if (!data || !data.date) {
    return {
      date: {
        startDate: '',
        endDate: ''
      },
      isLoading: true,
      isVisible: !errorGetDate
    }
  }

  return {
    date: {
      startDate: moment(data.date).format('YYYY-MM-DD'),
      endDate: moment(data.date).add(7, 'days').format('YYYY-MM-DD')
    },
    isLoading,
    isVisible: !errorGetDate
  }
}
