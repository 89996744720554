const allowedLanguagesForPricingPage = [
  'pl',
  'en',
  'pt',
  'es',
  'de',
  'fr',
  'it'
]

const getBrowserLanguageForPricingPage = () => {
  const browserLang = window.navigator?.language?.trim()

  const lang = browserLang?.split(/-|_/)[0]

  if (allowedLanguagesForPricingPage.includes(lang)) {
    return lang
  }

  return 'en'
}

const mainPricingPageUrls: Record<string, string> = {
  pt: 'https://landingi.com/pt-br/precos/',
  pl: 'https://landingi.com/pl/cennik/',
  en: 'https://landingi.com/pricing/',
  es: 'https://landingi.com/es/precios/',
  de: 'https://landingi.com/de/preise/',
  fr: 'https://landingi.com/fr/tarifs/',
  it: 'https://landingi.com/it/prezzi/'
}

export const getPricePageUrl = (languageFromQueryParams: string | null) =>
  mainPricingPageUrls[
    languageFromQueryParams || getBrowserLanguageForPricingPage()
  ]
