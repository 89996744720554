import { Icon } from '@ui-kit/Icon'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import { CountdownTypes } from './constants'
import styles from './CountdownTimer.module.scss'

interface CountdownBoxProps {
  value: string
  type: CountdownTypes
  isLoading?: boolean
}

export const CountdownBox: FC<CountdownBoxProps> = ({
  value,
  type,
  isLoading
}) => {
  const { t } = useTranslation()

  return (
    <Column
      className={styles['countdown-box']}
      horizontal='center'
      vertical='center'
    >
      {isLoading ? (
        <Icon icon='icon-spinner' color='white' spin />
      ) : (
        <Fragment>
          <span className={styles['countdown-box__value']}>{value}</span>

          <span className={styles['countdown-box__label']}>
            {t(`countdown.timer.${type}`)}
          </span>
        </Fragment>
      )}
    </Column>
  )
}
