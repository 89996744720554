import { ReactNode } from 'react'

import styles from './Infobars.module.scss'

interface InfobarContentProps {
  children: ReactNode
}

export const InfobarContent = ({ children }: InfobarContentProps) => (
  <span className={styles.alert__content}>{children}</span>
)
