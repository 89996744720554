import { MODE, PROD } from '@config/env'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

if (PROD) {
  Sentry.init({
    dsn: 'https://554db9e614674fbf988cc5b4d2854cd1@o56460.ingest.sentry.io/6081320',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    ignoreErrors: [
      'Illegal invocation',
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      'Failed to fetch',
      "Cannot read properties of undefined (reading 'hpm')",
      "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      'Non-Error promise rejection captured with value: Timeout'
    ],
    tracesSampleRate: 0.25,
    tracePropagationTargets: [/^https:\/\/app.landingi.com\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: MODE,
    denyUrls: [
      /freshchat/i,
      /survicate/i,
      /recaptcha\/releases\/rKbTvxTxwcw5VqzrtN-ICwWt\/recaptcha__.+/
    ]
  })
}
