import { ADDONS_IDS } from '@constants/addonsIds'
import LeadsAnonymization from '@images/addons/icons/LeadsAnonymization.svg'
import {
  FEATURE,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { POC_TEST, PocTestAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getLeadDataAnonymizationAddon: (
  t: TFunction<'translation', undefined>
) => PocTestAddonTemplate = t => ({
  type: POC_TEST,
  name: t('addons.store.leads.anonymization.name'),
  title: t('addons.store.leads.anonymization'),
  uuid: 'TODO',
  tileDescription: (
    <Trans i18nKey='addons.store.leads.anonymization.description' />
  ),
  pageDescription: t('addons.store.leads.anonymization.page.description'),
  badges: [FEATURE, SEVEN_DAYS_FREE_TRIAL],
  imageSrc: LeadsAnonymization,
  id: ADDONS_IDS.LEADS_ANONYMIZATION,
  overview: getOverview(t),
  features: getFeatures(),
  useCases: getUseCases(t),
  price: {
    1: {
      USD: 99,
      PLN: 379,
      GBP: 90,
      BRL: 399,
      EUR: 99
    }
  },
  pricing: getPricing(t),
  faq: getFAQ(t),
  isBeingTested: true
})
