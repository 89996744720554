import { RGBColor } from '@ui-kit/types/color'
import html2canvas from 'html2canvas'
import { useRef } from 'react'

export const useSelectPixelColor = (
  onColorChange: (newColor: RGBColor) => void,
  onStartPixelColorSelecting?: () => void,
  onFinishPixelColorSelecting?: () => void
) => {
  const screenshotCanvasRef = useRef<HTMLCanvasElement | null>(null)

  const takeScreenshot = async () => {
    const canvas = await html2canvas(document.body, {
      // allowTaint and useCORS are required to allow taking screenshots of cross-origin elements (e.g. images)
      allowTaint: true, // Kind of fixes "Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true"
      useCORS: true,
      logging: false // Ignores "Unable to get image data from canvas because the canvas has been tainted by cross-origin data."
    })
    screenshotCanvasRef.current = canvas

    return canvas
  }

  const handleFinishPixelColorSelecting = () => {
    // To prevent closing the dialog when user picks a color
    // we must SCHEDULE resetting the state deciding if dialog is closable
    setTimeout(() => onFinishPixelColorSelecting?.(), 0)

    document.removeEventListener('pointerup', handleFinishPixelColorSelecting)
  }

  const handlePixelSelect = (event: PointerEvent) => {
    const { clientX, clientY } = event
    const screenshotCanvas = screenshotCanvasRef.current
    const screenshotCtx = screenshotCanvas?.getContext('2d')

    if (screenshotCtx) {
      const pixelRatio = window.devicePixelRatio || 1
      const x = Math.round(clientX * pixelRatio)
      const y = Math.round(clientY * pixelRatio)

      const { data } = screenshotCtx.getImageData(x, y, 1, 1)

      const [r, g, b, a] = data
      const color: RGBColor = {
        r,
        g,
        b,
        a: a / 255
      }
      onColorChange(color)
    }

    document.removeEventListener('pointerdown', handlePixelSelect)

    document.addEventListener('pointerup', handleFinishPixelColorSelecting)
  }

  const selectPixelColor = async () => {
    const screenShotCanvas = await takeScreenshot()
    onStartPixelColorSelecting?.()
    document.addEventListener('pointerdown', handlePixelSelect)
    return screenShotCanvas
  }

  return {
    selectPixelColor
  }
}
