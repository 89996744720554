import { MutableRefObject, useCallback, useEffect, useRef } from 'react'

const initMoveValues = {
  initX: 0,
  initY: 0,
  offsetX: 0,
  offsetY: 0
}

interface UseMoveElement {
  /** @description ref to element which gonna be moving */
  movedElementRef: MutableRefObject<HTMLElement | null>
  /** @description ref to element which trigger event, default: movedElementRef */
  triggeringMoveElementRef?: MutableRefObject<HTMLElement | null>
}

export const useMoveElement = ({
  movedElementRef,
  triggeringMoveElementRef
}: UseMoveElement) => {
  const triggerMoveNode =
    triggeringMoveElementRef?.current || movedElementRef?.current

  const moveRef = useRef(initMoveValues)

  const moveAt = useCallback(
    (offsetX: number, offsetY: number) => {
      if (movedElementRef.current) {
        movedElementRef.current.style.transform = `translate(${offsetX}px, ${offsetY}px)`
      }
    },
    [movedElementRef]
  )

  const onMove = useCallback(
    (e: PointerEvent) => {
      moveRef.current.offsetX = e.clientX - moveRef.current.initX
      moveRef.current.offsetY = e.clientY - moveRef.current.initY

      moveAt(moveRef.current.offsetX, moveRef.current.offsetY)
    },
    [moveAt, moveRef]
  )

  const startMove = useCallback(
    (event: PointerEvent) => {
      const isInputAsTarget = (event.target as HTMLElement)?.tagName === 'INPUT'

      // prevent move if input is selecting to edit
      if (isInputAsTarget) {
        return
      }

      moveRef.current.initX = event.clientX - moveRef.current.offsetX
      moveRef.current.initY = event.clientY - moveRef.current.offsetY

      document.addEventListener('pointermove', onMove)

      document.addEventListener('pointerup', () => {
        document.removeEventListener('pointermove', onMove)
        document.onpointerup = null
      })
    },
    [moveRef, onMove]
  )

  const moveToDefaultPosition = useCallback(() => {
    moveRef.current = { ...initMoveValues }
    moveAt(moveRef.current.initX, moveRef.current.initY)
  }, [moveRef, moveAt])

  useEffect(() => {
    triggerMoveNode?.addEventListener('pointerdown', startMove)

    return () => {
      triggerMoveNode?.removeEventListener('pointerdown', startMove)
      moveRef.current = { ...initMoveValues }
    }
  }, [triggerMoveNode, startMove])

  return {
    moveToDefaultPosition,
    moveRef
  }
}
