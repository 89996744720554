import { InfobarAlert } from './components/InfobarAlert'
import { useGetAccountExpiresAtType } from './hooks/useGetAccountExpiresAtType'
import { useGetCreditCardExpiresAtType } from './hooks/useGetCreditCardExpiresAtType'
import { useGetFreePlansType } from './hooks/useGetFreePlansType'
import { useGetPaymentError } from './hooks/useGetPaymentError'

export const Infobars = () => {
  const infobarAccountExpiresAt = useGetAccountExpiresAtType()
  const infobarCreditCardExpiresAt = useGetCreditCardExpiresAtType()
  const infobarPaymentError = useGetPaymentError()
  const infobarFreePlans = useGetFreePlansType()

  if (infobarAccountExpiresAt) {
    return <InfobarAlert {...infobarAccountExpiresAt} />
  }

  if (infobarCreditCardExpiresAt) {
    return <InfobarAlert {...infobarCreditCardExpiresAt} />
  }

  if (infobarPaymentError) {
    return <InfobarAlert {...infobarPaymentError} />
  }

  if (infobarFreePlans) {
    return <InfobarAlert {...infobarFreePlans} />
  }

  return null
}

Infobars.displayName = 'TopbarInfobars'
