import { ErrorBoundary } from '@components/ErrorBoundary'
import { ProductUpdates } from '@components/ProductUpdates'
import { Sidebar } from '@components/Sidebar'
import { Topbar } from '@components/Topbar'
import { Infobars } from '@components/Topbar/components/Infobars'
import { SupportMessage } from '@components/Topbar/components/SupportMessage'
import { useFeatureFlags } from '@contexts/featureFlags'
import { SidebarProvider } from '@contexts/sidebar'
import { useStyles } from '@hooks/useStyles'
import { Loader } from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import { Fragment, Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import styles from './Full.module.scss'

export const FullLayout = () => {
  const location = useLocation()
  const isWelcomePage = location.pathname === LANDINGS.WELCOME

  const hasAccessToHideSidebarOnWelcomePage = useFeatureFlags(
    'HIDE_SIDEBAR_ON_WELCOME'
  )

  const contentWrapperStyles = useStyles({
    [styles['content-wrapper']]: true,
    [styles['content-wrapper__no-sidebar']]:
      isWelcomePage && hasAccessToHideSidebarOnWelcomePage
  })

  const shouldRenderSidebar =
    !hasAccessToHideSidebarOnWelcomePage || !isWelcomePage

  return (
    <Fragment>
      <Infobars />

      <SidebarProvider>{shouldRenderSidebar && <Sidebar />}</SidebarProvider>

      <Topbar />

      <ProductUpdates />

      <div className={contentWrapperStyles}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <SupportMessage />

            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Fragment>
  )
}

FullLayout.displayName = 'FullLayoutLayout'

export default FullLayout
