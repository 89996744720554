import { getLocalStorage } from '@helpers/storage'
import { Paragraph } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { REGISTRATION } from '@routes/path'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Row } from 'simple-flexbox'

export const StartTrialCTA = () => {
  const { searchParams } = useRegisterContext()

  let registrationRoute

  if (getLocalStorage('entryRoute') === 'signup') {
    registrationRoute = REGISTRATION.SIGNUP.DEFAULT
  } else {
    registrationRoute = REGISTRATION.REGISTER.DEFAULT
  }

  return (
    <Row justifyContent='center'>
      <Paragraph size={12} color='accent-2' align='right'>
        <Trans
          i18nKey='registration.flow.dont.have.account'
          components={{
            a: <Link to={`${registrationRoute}?${searchParams.toString()}`} />
          }}
        />
      </Paragraph>
    </Row>
  )
}

StartTrialCTA.displayName = 'Start trial CTA'

export const LoginCTA = () => {
  const { searchParams } = useRegisterContext()

  return (
    <Row justifyContent='center'>
      <Paragraph size={12} padding='none'>
        <Trans
          i18nKey='registration.flow.already.have.account.with.description'
          components={{
            a: (
              <Link
                to={`${REGISTRATION.LOGIN.DEFAULT}?${searchParams.toString()}`}
              />
            ),
            b: <b />
          }}
        />
      </Paragraph>
    </Row>
  )
}

LoginCTA.displayName = 'Login CTA'
