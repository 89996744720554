import { useHover, UseHoverProps } from '@hooks/useHover'
import { BoxOutlineStatusType } from '@ui-kit/BoxOutline//types'
import { getStatus } from '@ui-kit/BoxOutline/helpers'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react'

export type BoxOutlineProviderProps = {
  children: ReactNode
  disabled?: boolean
  controlledActive?: boolean
}

type BoxOutlineContextValue = {
  hoverProps: UseHoverProps
  status: BoxOutlineStatusType
  setIsActive: Dispatch<SetStateAction<boolean>>
  isDisabled: boolean
  isActive: boolean
  isHovered: boolean
}

const BoxOutlineContext = createContext<BoxOutlineContextValue | null>(null)

export const BoxOutlineProvider: FC<BoxOutlineProviderProps> = ({
  children,
  disabled,
  controlledActive
}) => {
  const [hoverProps, isHovered] = useHover()
  const [isActive, setIsActive] = useState(false)
  const isDisabled = !!disabled

  const status = getStatus({
    isHovered,
    isActive: controlledActive || isActive,
    isDisabled
  })

  const ctxValue = useMemo(
    () => ({
      hoverProps,
      status,
      setIsActive,
      isDisabled,
      isActive: controlledActive || isActive,
      isHovered
    }),
    [hoverProps, status, isDisabled, isActive, isHovered, controlledActive]
  )

  return (
    <BoxOutlineContext.Provider value={ctxValue}>
      {children}
    </BoxOutlineContext.Provider>
  )
}

export const useBoxOutlineContext = () => {
  const ctxValue = useContext(BoxOutlineContext)

  if (!ctxValue) {
    throw new Error(
      'useBoxOutlineContext must be used inside BoxOutlineProvider'
    )
  }

  return ctxValue
}
