import { APP_LANDINGI_URL, DEV, PROD } from '@config/env'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import axios from 'axios'

const client = axios.create({
  baseURL: `${APP_LANDINGI_URL}/api`,
  timeout: DEV ? 30000 : 10000
})

client.interceptors.request.use(request => {
  const isGetMethod = request?.method === 'get'

  request.withCredentials = true

  if (!isGetMethod && window['landingi-xsrf-token']) {
    request.headers['x-xsrf-token'] = window['landingi-xsrf-token']
    request.xsrfCookieName = undefined
  }

  return request
})

client.interceptors.response.use(
  response => {
    if (response?.headers?.['x-xsrf-token']) {
      window['landingi-xsrf-token'] = response?.headers?.['x-xsrf-token']
    }

    return response
  },
  error => {
    const { response } = error

    if (response?.headers?.['x-xsrf-token']) {
      window['landingi-xsrf-token'] = response?.headers?.['x-xsrf-token']
    }

    return Promise.reject(error)
  }
)

const newLandingiClient = axios.create({
  baseURL: DEV ? '/new-landingi-app' : NEW_LANDINGI_APP_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'X-Spa-Origin': `app.landingi.${PROD ? 'com' : 'it'}`
  }
})

export { newLandingiClient }

export default client
