import { ADDONS_IDS } from '@constants/addonsIds'
import { abTestsAddonPrices } from '@constants/addonsPrices'
import { ADDONS_UUIDS } from '@constants/addonsUuids'
import { PLANS } from '@constants/plans'
import ABTests from '@images/addons/icons/ABTests.svg'
import {
  FEATURE,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { ABTestsScheduleDemoButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import { BUYABLE, BuyableAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getABTestsAddon: (
  t: TFunction<'translation', undefined>
) => BuyableAddonTemplate = t => ({
  type: BUYABLE,
  name: t('addons.store.dashboard.ab.tests.name'),
  title: t('addons.store.dashboard.ab.tests'),
  tileDescription: t('addons.store.dashboard.ab.tests.description'),
  badges: [FEATURE, SEVEN_DAYS_FREE_TRIAL],
  imageSrc: ABTests,
  id: ADDONS_IDS.OPTIMIZATION_ADDON,
  uuid: ADDONS_UUIDS.OPTIMIZATION_ADDON,
  pageDescription: t('addons.store.dashboard.ab.tests.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  price: abTestsAddonPrices,
  pricing: getPricing(),
  useCases: getUseCases(t),
  faq: getFAQ(t),
  featureFlag: 'TEST_AB',
  secondaryButton: ABTestsScheduleDemoButton,
  limitedInPlans: [PLANS.FREE_LIMITED_24, PLANS.LITE_LIMITED_24]
})
