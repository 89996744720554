import {
  COMPANY_PRESENTATION,
  CONTEST_SIGNUP,
  EVENT_SIGNUP,
  LpGoal,
  MOBILE_APP_PROMOTION,
  NEWSLETTER_SIGNUP,
  OFFER_DOWNLOAD,
  PRODUCT_SALE,
  RECRUITMENT,
  RESOURCE_DOWNLOAD,
  SAAS_REGISTRATION,
  SCHEDULE_MEETING,
  SERVICE_SALE,
  WAITLIST,
  WEBINAR_SIGNUP
} from '../constants/lpGoals'

const heroSectionStyles = {
  modern: {
    [COMPANY_PRESENTATION]: ['modern_12', 'modern_13'],
    [CONTEST_SIGNUP]: ['modern_01', 'modern_02'],
    [EVENT_SIGNUP]: ['modern_08', 'modern_09'],
    [MOBILE_APP_PROMOTION]: ['modern_11', 'modern_14', 'modern_15'],
    [NEWSLETTER_SIGNUP]: ['modern_01', 'modern_02'],
    [OFFER_DOWNLOAD]: ['modern_06', 'modern_07'],
    [PRODUCT_SALE]: ['modern_12', 'modern_13'],
    [RECRUITMENT]: ['modern_12', 'modern_13'],
    [RESOURCE_DOWNLOAD]: ['modern_04'],
    [SAAS_REGISTRATION]: ['modern_05', 'modern_10'],
    [SCHEDULE_MEETING]: ['modern_03'],
    [SERVICE_SALE]: ['modern_12', 'modern_13'],
    [WAITLIST]: ['modern_01', 'modern_02'],
    [WEBINAR_SIGNUP]: ['modern_08', 'modern_09']
  },
  elegant: {
    [COMPANY_PRESENTATION]: [
      'elegant-hero-section-1',
      'elegant-hero-section-2'
    ],
    [CONTEST_SIGNUP]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [EVENT_SIGNUP]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [MOBILE_APP_PROMOTION]: [
      'elegant-hero-section-1',
      'elegant-hero-section-2'
    ],
    [NEWSLETTER_SIGNUP]: ['elegant-hero-section-1'],
    [OFFER_DOWNLOAD]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [PRODUCT_SALE]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [RECRUITMENT]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [RESOURCE_DOWNLOAD]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [SAAS_REGISTRATION]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [SCHEDULE_MEETING]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [SERVICE_SALE]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [WAITLIST]: ['elegant-hero-section-1', 'elegant-hero-section-2'],
    [WEBINAR_SIGNUP]: ['elegant-hero-section-1', 'elegant-hero-section-2']
  },
  dark: {
    [COMPANY_PRESENTATION]: ['dark-hero-section-1'],
    [CONTEST_SIGNUP]: ['dark-hero-section-1'],
    [EVENT_SIGNUP]: ['dark-hero-section-1'],
    [MOBILE_APP_PROMOTION]: ['dark-hero-section-1'],
    [NEWSLETTER_SIGNUP]: ['dark-hero-section-1'],
    [OFFER_DOWNLOAD]: ['dark-hero-section-1'],
    [PRODUCT_SALE]: ['dark-hero-section-1'],
    [RECRUITMENT]: ['dark-hero-section-1'],
    [RESOURCE_DOWNLOAD]: ['dark-hero-section-1'],
    [SAAS_REGISTRATION]: ['dark-hero-section-1'],
    [SCHEDULE_MEETING]: ['dark-hero-section-1'],
    [SERVICE_SALE]: ['dark-hero-section-1'],
    [WAITLIST]: ['dark-hero-section-1'],
    [WEBINAR_SIGNUP]: ['dark-hero-section-1']
  },
  minimal: {
    [COMPANY_PRESENTATION]: ['minimal-hero-section-1'],
    [CONTEST_SIGNUP]: ['minimal-hero-section-1'],
    [EVENT_SIGNUP]: ['minimal-hero-section-1'],
    [MOBILE_APP_PROMOTION]: ['minimal-hero-section-1'],
    [NEWSLETTER_SIGNUP]: ['minimal-hero-section-1'],
    [OFFER_DOWNLOAD]: ['minimal-hero-section-1'],
    [PRODUCT_SALE]: ['minimal-hero-section-1'],
    [RECRUITMENT]: ['minimal-hero-section-1'],
    [RESOURCE_DOWNLOAD]: ['minimal-hero-section-1'],
    [SAAS_REGISTRATION]: ['minimal-hero-section-1'],
    [SCHEDULE_MEETING]: ['minimal-hero-section-1'],
    [SERVICE_SALE]: ['minimal-hero-section-1'],
    [WAITLIST]: ['minimal-hero-section-1'],
    [WEBINAR_SIGNUP]: ['minimal-hero-section-1']
  }
} as const

export type SectionType = string

export const isSectionForGoal = (
  style: 'modern' | 'dark' | 'elegant' | 'minimal',
  goal: LpGoal,
  previousSection?: SectionType
) => {
  const lpGoal = goal

  const goalSections = heroSectionStyles[style][lpGoal]

  return (
    previousSection &&
    (goalSections as readonly string[]).includes(previousSection)
  )
}

export const randomizeHeroSection = (
  style: 'modern' | 'dark' | 'elegant' | 'minimal',
  goal: LpGoal
) => {
  const lpGoal = goal

  const goalSections = heroSectionStyles[style][lpGoal]

  const randomIndex = Math.floor(Math.random() * goalSections.length)

  const sectionId = goalSections[randomIndex]

  return sectionId
}
