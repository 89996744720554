import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step
} from 'react-joyride'

import defaultStyles from './config/styles'

interface TourProps {
  steps: Step[]
  targetName: string
  disableScrolling?: boolean
  delay?: number
  hideProgress?: boolean
}

export const Tour: FC<TourProps> = ({
  steps,
  targetName,
  disableScrolling = true,
  delay,
  hideProgress = false
}) => {
  const { t } = useTranslation()

  const shouldActiveTour = !(
    getLocalStorage(`is-active-tour-${targetName}`) === 'false'
  )

  const [isActive, setIsActive] = useState(delay ? false : shouldActiveTour)
  const [stepIndex, setStepIndex] = useState(0)

  setTimeout(() => {
    setIsActive(shouldActiveTour)
  }, delay)

  const onHandleTourCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, type, status } = data

      const stepTypes: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]

      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

      if (stepTypes.includes(type)) {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
      }

      if (finishedStatuses.includes(status)) {
        setLocalStorage(`is-active-tour-${targetName}`, false)
        setIsActive(false)
      }
    },
    [targetName]
  )

  return isActive ? (
    <Joyride
      callback={onHandleTourCallback}
      run
      stepIndex={stepIndex}
      steps={steps}
      styles={defaultStyles}
      scrollDuration={600}
      disableScrolling={disableScrolling}
      hideBackButton
      disableCloseOnEsc
      hideCloseButton
      showSkipButton
      continuous
      locale={{
        close: t('word.close'),
        skip: t('word.close'),
        next: t('word.next')
      }}
      floaterProps={{
        styles: {
          arrow: {
            spread: 12,
            length: 6
          }
        }
      }}
      showProgress={!hideProgress && steps.length > 1}
      spotlightPadding={0}
    />
  ) : null
}
