import { debounce } from '@helpers/events'
import { useStyles } from '@hooks/useStyles'
import { DropdownList, DropdownListSize } from '@ui-kit/DropdownList'
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { mergeRefs, useLayer } from 'react-laag'

import styles from './Dropdown.module.scss'

const possiblePlacements = [
  'bottom-start',
  'bottom-end',
  'bottom-center',
  'top-start',
  'top-center',
  'top-end'
] as const

type PlacementType = (typeof possiblePlacements)[number]

export interface DropdownProps {
  children: FC<{ close: () => void }>
  size?: DropdownListSize
  trigger: FC<{ isOpen: boolean; isDisabled: boolean }>
  placement?: PlacementType
  isDisabled?: boolean
  onOutsideClick?: () => void
  onOpen?: () => void
  'data-testid'?: string
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  size = 'auto',
  trigger: Trigger,
  placement = 'bottom-end',
  isDisabled = false,
  onOutsideClick,
  onOpen,
  'data-testid': dataTestId = 'dropdown-trigger'
}) => {
  const [isOpen, setOpen] = useState(false)
  const triggerRef = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<{ width: string } | {}>({ width: '' })

  const handleResize = useCallback(() => {
    if (size !== 'full-width' || !triggerRef.current) {
      return
    }

    const container = triggerRef.current.getBoundingClientRect()

    setStyle({ width: container?.width })
  }, [size])

  const debouncedHandleResize = useCallback(
    () => debounce(handleResize, 50),
    [handleResize]
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize as EventListener)

    return () => {
      window.removeEventListener(
        'resize',
        debouncedHandleResize as EventListener
      )
    }
  }, [debouncedHandleResize])

  const toggleOpen = useCallback(() => {
    if (!isDisabled) {
      setOpen(prevState => {
        const isOpen = !prevState

        return isOpen
      })

      handleResize()
    }
  }, [isDisabled, handleResize])

  useEffect(() => {
    if (isOpen) {
      onOpen?.()
    }
  }, [isOpen])

  const close = useCallback(() => setOpen(false), [])

  const handleOutsideClick = useCallback(() => {
    close()
    if (onOutsideClick) {
      onOutsideClick()
    }
  }, [close, onOutsideClick])

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 5,
    auto: true,
    placement,
    possiblePlacements: [...possiblePlacements],
    onOutsideClick: handleOutsideClick,
    onParentClose: close
  })

  const dropdownTriggerStyles = useStyles({
    [styles.dropdown__trigger]: true,
    [styles.dropdown__triggerDisabled]: isDisabled
  })

  const dropdownStyles = useStyles({
    [styles.dropdown]: true
  })

  return (
    <Fragment>
      <div
        {...triggerProps}
        data-testid={dataTestId}
        className={dropdownTriggerStyles}
        ref={mergeRefs(triggerRef, triggerProps.ref)}
        onClick={toggleOpen}
      >
        <Trigger isOpen={isOpen} isDisabled={isDisabled} />
      </div>

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              ...styles,
              ...style
            }}
            data-testid='dropdown-layer'
            className={dropdownStyles}
          >
            <DropdownList size={size}>{children({ close })}</DropdownList>
          </div>
        )}
    </Fragment>
  )
}

Dropdown.displayName = 'Dropdown'
