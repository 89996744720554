import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { pallets } from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/constants'
import type { PalleteNumber } from '@pages/Landings/routes/Landings/CreateLandingWizard/types'
import { useGetFontsList } from '@services/fonts/useGetFontsList'
import { Spacer, Spreader, Text } from '@ui-kit'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

import { Palette } from './Palette'

export const PaletteStyleBox = ({ palette }: { palette: PalleteNumber }) => {
  const { data } = useGetFontsList()
  const {
    formik: {
      values: { style }
    }
  } = useWizardContext()

  const { colors, font } = pallets[style!][palette]

  const renderFont = () => {
    if (data) {
      const fontName = font
      const fontUrl = data.collection[fontName.toLowerCase()].css_url

      return (
        <Fragment>
          <link href={fontUrl} rel='stylesheet' />

          <span
            style={{
              fontFamily: fontName
            }}
          >
            {fontName}
          </span>
        </Fragment>
      )
    }
    return null
  }

  return (
    <Fragment>
      <Palette colors={colors} />

      <Spacer space={10} />

      <Row alignItems='center'>
        <Text color='neutral-4' size={12}>
          Font:
        </Text>
        <Spreader spread={5} />
        {renderFont()}
      </Row>
    </Fragment>
  )
}
