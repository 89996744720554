import { CurrencyType } from '@constants/currencies'
import { splitPriceByFloatingPoint } from '@helpers/payment'
import { Text } from '@ui-kit/Text'
import { FC, ReactNode } from 'react'
import { Column, Row } from 'simple-flexbox'

import styles from './Price.module.scss'

export interface PriceProps {
  amount: number
  currency: CurrencyType
  children?: ReactNode
}

export const Price: FC<PriceProps> = ({ amount, currency, children }) => {
  const { decimalPoint, floatingPoint } = splitPriceByFloatingPoint(amount)

  return (
    <Row className={styles.price}>
      <Text
        className={styles.price__amount}
        weight={600}
        color='primary'
        data-testid='price-amount'
      >
        {decimalPoint}

        {floatingPoint && (
          <sup className={styles['price__floating-point']}>
            {`,${floatingPoint}`}
          </sup>
        )}
      </Text>

      <Column>
        <Text
          className={styles.price__currency}
          size={14}
          weight={600}
          color='primary'
          data-testid='price-currency'
        >
          {currency}
        </Text>

        {children}
      </Column>
    </Row>
  )
}
