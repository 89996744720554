import i18next from 'i18next'

// Check if value is empty
export const isEmpty = (value: any) =>
  !value ||
  (Object.prototype.hasOwnProperty.call(value, 'length') &&
    value.length === 0) ||
  (value.constructor === Object && Object.keys(value).length === 0)

// Default labels for filters in table
export const defaultLabels = {
  landing_info: '-created_at',
  all_types: '2',
  product_info: 'created_at'
}

// Format price (ex. 10000 => 10 000)
export const formatPrice = (
  value: number | string,
  separator: string = ' '
): string => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)

//  Format numeric (ex. 10000 => 10 000)
export const formatNumeric = (value: string | number) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

//  Format numeric based on language (ex. en: 10000 => 10,000, pl: 10000 => 10 000, pt: 10000 => 10.000)
export const formatNumericBasedOnLanguage = (value: number) => {
  const currentLanguage = i18next.language

  return new Intl.NumberFormat(currentLanguage, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(value)
}

// A function that returns fake universally unique identifier (uuid)
export const generateFakeUuid = () => {
  const hashTable = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9'
  ]

  const uuid = []

  for (let i = 0; i < 35; i++) {
    if (i === 7 || i === 12 || i === 17 || i === 22) {
      uuid[i] = '-'
    } else {
      uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
    }
  }

  return uuid.join('')
}
