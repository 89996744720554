import Dark from '@images/landings/wizard/dark.png'
import Elegant from '@images/landings/wizard/elegant.png'
import Minimal from '@images/landings/wizard/minimal.png'
import Modern from '@images/landings/wizard/modern.png'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { LpStyle } from '@pages/Landings/routes/Landings/CreateLandingWizard/types'
import { BoxOutline, BoxOutlineProvider, Image, Radio } from '@ui-kit'

import styles from '../Fourth.module.scss'

const images = {
  dark: Dark,
  elegant: Elegant,
  minimal: Minimal,
  modern: Modern
}

export const StyleBox = ({ lpStyle }: { lpStyle: LpStyle }) => {
  const { formik, setStyle } = useWizardContext()
  const { values } = formik

  return (
    <BoxOutlineProvider controlledActive={values.style === lpStyle}>
      <BoxOutline padding={5} onClick={() => setStyle(lpStyle)}>
        <Radio
          label={lpStyle}
          id={lpStyle}
          field={{
            name: lpStyle,
            value: values.style,
            onChange: () => setStyle(lpStyle)
          }}
          className={styles['fourth-step__radio']}
        />
        <Image src={images[lpStyle]} alt='modern' width='100%' />
      </BoxOutline>
    </BoxOutlineProvider>
  )
}
