import {
  CustomLink,
  Heading,
  Paragraph,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { REGISTRATION } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import RecoveryCodeForm from './Form'
import styles from './RecoveryCode.module.scss'

/**
 * RecoveryCode - stateless presentational component
 * @return {object} An object of children
 */
const RecoveryCode = () => {
  const { t } = useTranslation()
  const { lang, variantName } = useRegisterContext()

  return (
    <Column className={styles['register-cta']}>
      <Heading level={1} bold align='center'>
        {t('two.factor.authentication.page.recovery.code.heading')}
      </Heading>

      <Paragraph size={16} align='center'>
        {t('two.factor.authentication.enter.one.of.a.codes')}
      </Paragraph>

      <Spacer space='medium' />

      <RecoveryCodeForm />

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Row justifyContent='space-between'>
        <Paragraph size={12} color='accent-2'>
          {t('two.factor.authentication.found.your.phone')}

          <Link
            to={`${REGISTRATION.TWO_FACTOR_AUTHENTICATION.DEFAULT}?lang=${lang}&v=${variantName}`}
          >
            <Trans i18nKey='two.factor.authentication.found.your.phone.link' />
          </Link>
        </Paragraph>

        <Paragraph size={12} color='accent-2' align='right'>
          {t('two.factor.authentication.cant.find')}
          <CustomLink
            target='_blank'
            size={12}
            href={t('word.create-support-ticket.url')}
            label={t('two.factor.authentication.cant.find.link')}
            bold
          />
        </Paragraph>
      </Row>
    </Column>
  )
}

RecoveryCode.displayName = 'RecoveryCode'

export default RecoveryCode
