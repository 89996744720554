import { useStyles } from '@hooks/useStyles'
import { Paragraph } from '@ui-kit/Paragraph'
import { ChangeEventHandler, forwardRef, InputHTMLAttributes } from 'react'

import styles from './Input.module.scss'

export interface InputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'className' | 'type' | 'size'
  > {
  className?: string | string[]
  variant?: 'border-dashed' | 'border'
  id: string
  value?: string | number
  onChange?: ChangeEventHandler<HTMLInputElement>
  size?: 14 | 16
  type?: 'text' | 'number' | 'password'
  i18n?: {
    label?: string
    placeholder?: string
    error?: string
  }
  required?: boolean
  hasError?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      variant = 'border',
      id,
      size = 14,
      type = 'text',
      i18n,
      required = true,
      hasError = false,
      ...restProps
    },
    ref
  ) => {
    const inputFieldStyles = useStyles(
      {
        [styles['input-field']]: true,
        [styles['input-field--error']]: hasError,
        [styles[`input-field--size-${size}`]]: true,
        [styles[`input-field--has-placeholder`]]: i18n?.placeholder,
        [styles[`input-field--variant-${variant}`]]: variant
      },
      className
    )

    const errorSize = {
      14: 10,
      16: 12
    } as const

    return (
      <div className={inputFieldStyles} data-testid={`input-field-${id}`}>
        <input
          {...restProps}
          className={styles['input-field__input']}
          ref={ref}
          id={id}
          type={type}
          required={required}
          placeholder={i18n?.placeholder}
          data-testid={`input-${id}`}
        />

        {i18n?.label && (
          <label className={styles['input-field__label']} htmlFor={id}>
            {i18n.label}
          </label>
        )}

        {hasError && (
          <Paragraph color='error' size={errorSize[size]}>
            {i18n?.error}
          </Paragraph>
        )}
      </div>
    )
  }
)

Input.displayName = 'Input'
