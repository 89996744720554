import client from '@lib/http/client'

interface SetSignInDateRequest {
  action: string
}

export const setSignInDate = ({ action }: SetSignInDateRequest) =>
  client.post('auth/sign-in-date/set-date', {
    action
  })
