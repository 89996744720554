import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import type { Pallete } from '@pages/Landings/routes/Landings/CreateLandingWizard/types'
import { BoxOutline, BoxOutlineProvider } from '@ui-kit'

import { CustomStyleBox } from './CustomStyleBox'
import { PaletteStyleBox } from './PaletteStyleBox'
import styles from './StyleBox.module.scss'

export const StyleBox = ({
  palette,
  onClick
}: {
  palette: Pallete | 'custom'
  onClick?: () => void
}) => {
  const { formik } = useWizardContext()
  const { values, setFieldValue } = formik

  return (
    <BoxOutlineProvider controlledActive={values.palette === palette}>
      <BoxOutline
        padding={15}
        onClick={() => {
          onClick?.()
          return setFieldValue('palette', palette)
        }}
        className={styles.panel}
      >
        {palette === 'custom' ? (
          <CustomStyleBox />
        ) : (
          <PaletteStyleBox palette={palette} />
        )}
      </BoxOutline>
    </BoxOutlineProvider>
  )
}
