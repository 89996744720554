import {
  convertStartDate,
  convertTargetDate,
  getReturnValues
} from '@ui-kit/CountdownTimer/helpers'
import { useEffect, useState } from 'react'

interface UseCountdownProps {
  startDate: string
  endDate: string
}

export const useCountdownFreePlansTriggerToUpgrade = ({
  startDate,
  endDate
}: UseCountdownProps) => {
  const [countDown, setCountDown] = useState(0)

  const isFinished = countDown <= 0 && (!!startDate || !!endDate)

  useEffect(() => {
    const countDownStartDate = startDate
      ? Number(convertStartDate(startDate))
      : 0
    const countDownEndDate = endDate ? Number(convertTargetDate(endDate)) : 0

    setCountDown(countDownEndDate - countDownStartDate)

    const interval = setInterval(() => {
      if (isFinished) {
        clearInterval(interval)
        return
      }

      setCountDown(prevCountDown => prevCountDown - 1000)
    }, 1000)

    return () => clearInterval(interval)
  }, [startDate, endDate, isFinished])

  return startDate ? getReturnValues(countDown, isFinished) : { isFinished }
}
