import { convertHexColorToRgba, convertRgbaToHex } from '@helpers/color'
import { useStyles } from '@hooks/useStyles'
import { ColorPickerDialog } from '@ui-kit/ColorPickerDialog'
import { ColorTints } from '@ui-kit/ColorTints/ColorTints'
import { HexInput } from '@ui-kit/Input'
import { RGBColor } from '@ui-kit/types/color'
import { FC, useCallback } from 'react'

import styles from './ColorTintsTile.module.scss'

export interface ColorTintsTileProps {
  colorValue: RGBColor
  onColorChange: (newColor: RGBColor) => void
  className?: string
  'data-testid'?: string
}

export const ColorTintsTile: FC<ColorTintsTileProps> = ({
  colorValue,
  onColorChange,
  className = '',
  'data-testid': dataTestId = 'color-tints-tile'
}) => {
  const classNames = useStyles(
    {
      [styles.colorTintsTile]: true
    },
    className
  )

  const handleConfirmHexInput = useCallback(
    (hexColorValue: string) => {
      onColorChange(convertHexColorToRgba(hexColorValue))
    },
    [onColorChange]
  )

  return (
    <div className={classNames} data-testid={dataTestId}>
      <ColorTints
        colorValue={colorValue}
        size='small'
        data-testid='color-tints'
      />
      <div className={styles.inputs}>
        <HexInput
          className={styles.hex}
          id='color-tints-hex-input'
          value={convertRgbaToHex(colorValue)}
          onConfirm={handleConfirmHexInput}
        />

        <ColorPickerDialog
          colorValue={colorValue}
          onColorChange={onColorChange}
        />
      </div>
    </div>
  )
}
