import { isEmpty } from '@helpers/data'
import { createContext, MutableRefObject, useContext } from 'react'

import { DialogProps } from '../types'

type DialogContextType = Pick<
  DialogProps,
  'size' | 'moveToPlacementOnClose'
> & {
  handleClose: () => void
  moveToDefaultPosition: () => void
  triggeringMoveElementRef: MutableRefObject<HTMLElement | null>
}

export const DialogContext = createContext({} as Required<DialogContextType>)

export const useDialogContext = () => {
  const ctxValue = useContext(DialogContext)

  if (isEmpty(ctxValue)) {
    throw new Error(
      'useDialogContext can not be used outside DialogContext.Provider'
    )
  }

  return ctxValue
}
