import React, { FC } from 'react'

export interface YoutubeEmbedProps {
  videoID: string
  width?: number
  height?: number
}

export const YoutubeEmbed: FC<YoutubeEmbedProps> = ({
  videoID,
  width = 560,
  height = 315
}) => (
  <iframe
    width={width}
    height={height}
    src={`https://www.youtube-nocookie.com/embed/${videoID}?rel=0`}
    title='YouTube video player'
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-shar;'
    referrerPolicy='strict-origin-when-cross-origin'
    allowFullScreen
    data-testid='youtube-embed'
  />
)

YoutubeEmbed.displayName = 'YoutubeEmbed'
