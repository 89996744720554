import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { useGetFontsList } from '@services/fonts/useGetFontsList'
import {
  Badge,
  Button,
  Dropdown,
  DropdownElement,
  Heading,
  Icon,
  Input,
  Paragraph,
  Spacer,
  Spreader
} from '@ui-kit'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './CustomFont.module.scss'

const fontInputId = 'fontSearchInput'

export const CustomFont = () => {
  const { t } = useTranslation()

  const { formik } = useWizardContext()
  const { values, setFieldValue } = formik

  const { dataAsArray } = useGetFontsList()

  const [inputValue, setInputValue] = useState(values.customPalette.font)

  const customTrigger = ({ isOpen }: { isOpen: boolean }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
    }

    return (
      <Row>
        <Input
          id={fontInputId}
          className={styles.input}
          value={inputValue}
          onChange={handleChange}
          autoFocus={isOpen}
        />
        <Icon
          icon={`icon-caret-${isOpen ? 'up' : 'down'}`}
          color='neutral-6'
          size={16}
        />
      </Row>
    )
  }

  return (
    <Column>
      {dataAsArray.map(font => {
        return <link href={font.css_url} rel='stylesheet' />
      })}
      <Heading weight={600} level={5}>
        {t('landings.create.wizard.landing.modal.custom.style.font.title')}
      </Heading>

      <Spacer space={15} />

      <Column className={styles.fontBox}>
        <Column
          className={styles.preview}
          justifyContent='center'
          alignItems='center'
        >
          <span
            style={{
              fontFamily: values.customPalette.font
            }}
          >
            {values.customPalette.font}
          </span>
        </Column>
        <div className={styles.dropdownBox}>
          <Dropdown
            placement='top-start'
            trigger={customTrigger}
            onOutsideClick={() => {
              setInputValue(values.customPalette.font)
            }}
            onOpen={() => {
              setInputValue('')
            }}
          >
            {({ close }) => (
              <Fragment>
                <div className={styles.dropdownContent}>
                  {dataAsArray
                    .filter(font =>
                      font.name.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map(font => {
                      return (
                        <DropdownElement
                          className={styles.dropdownElement}
                          key={font.name}
                          onClick={() => {
                            setInputValue(font.name)
                            setFieldValue('customPalette.font', font.name)
                            close()
                          }}
                        >
                          <span
                            style={{
                              fontFamily: font.name
                            }}
                          >
                            {font.name}
                          </span>
                          <Spreader spread={5} />
                          {font.type !== 'generic' && (
                            <Badge variant='info-border'>
                              {t(
                                'landings.create.wizard.landing.modal.custom.style.custom.badge'
                              )}
                            </Badge>
                          )}
                        </DropdownElement>
                      )
                    })}
                </div>

                <Row
                  className={styles.addNewFont}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Paragraph size={10}>
                    {t(
                      'landings.create.wizard.landing.modal.custom.style.add.font.desc'
                    )}
                  </Paragraph>
                  <Button variant='text-primary' icon='icon-cloud-upload'>
                    {t(
                      'landings.create.wizard.landing.modal.custom.style.add.font.button'
                    )}
                  </Button>
                </Row>
              </Fragment>
            )}
          </Dropdown>
        </div>
      </Column>
    </Column>
  )
}
