import { KeyboardKey } from '@constants/keyboardKey'
import { useKeyPress } from '@hooks/useKeyPress'
import { useStyles } from '@hooks/useStyles'
import { Panel } from '@ui-kit/Panel'
import { FC, Fragment, useCallback, useMemo, useRef } from 'react'
import { useLayer } from 'react-laag'

import { DialogContext } from './context'
import styles from './Dialog.module.scss'
import { useMoveElement } from './hooks/useMoveElement'
import { DialogProps } from './types'

export const Dialog: FC<DialogProps> = ({
  className = '',
  renderTrigger,
  children,
  isOpen,
  onClose,
  onPointerUpOnLayer,
  triggerOffset = 5,
  placement = 'bottom-center',
  size = 14,
  moveToPlacementOnClose = true,
  isClosableOnClickOutside = true
}) => {
  const layerStyles = useStyles(
    {
      [styles['dialog__layer--hidden']]: !isOpen
    },
    className
  )

  const movedElementRef = useRef<HTMLDivElement | null>(null)

  const triggeringMoveElementRef = useRef<HTMLDivElement | null>(null)

  const { moveToDefaultPosition } = useMoveElement({
    movedElementRef,
    triggeringMoveElementRef
  })

  const handleClose = useCallback(() => {
    onClose()

    if (moveToPlacementOnClose) {
      moveToDefaultPosition()
    }
  }, [onClose, moveToPlacementOnClose, moveToDefaultPosition])

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    auto: true,
    triggerOffset,
    placement,
    onOutsideClick: isClosableOnClickOutside ? handleClose : undefined
  })

  const dialogCtx = useMemo(
    () => ({
      handleClose,
      size,
      triggeringMoveElementRef,
      moveToDefaultPosition,
      moveToPlacementOnClose
    }),
    [
      handleClose,
      size,
      triggeringMoveElementRef,
      moveToDefaultPosition,
      moveToPlacementOnClose
    ]
  )

  useKeyPress(KeyboardKey.Escape, handleClose)

  return (
    <Fragment>
      <span {...triggerProps} className={styles.dialog__trigger}>
        {renderTrigger}
      </span>

      <DialogContext.Provider value={dialogCtx}>
        {renderLayer(
          <div ref={movedElementRef} onPointerUp={onPointerUpOnLayer}>
            <Panel {...layerProps} className={layerStyles} padding={0}>
              {children}
            </Panel>
          </div>
        )}
      </DialogContext.Provider>
    </Fragment>
  )
}

Dialog.displayName = 'Dialog'
