import { NEW_LANDINGI_APP_URL } from '@constants/index'
import GenerateWithWizpage from '@images/landings/wizard/generate-with-wizpage.png'
import Templates from '@images/landings/wizard/select-template.png'
import BlankPage from '@images/landings/wizard/start-with-blank-page.png'
import { LANDINGS } from '@routes/path'
import { Badge, Heading, Icon, Spacer, Spreader, Text } from '@ui-kit'
import { Close } from '@ui-kit/Close'
import { motion } from 'framer-motion'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { ChooseMethodBoxOutline } from './components/CreateLandingBoxOutline'
import styles from './CreateLanding.module.scss'

const NewBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='info-filled'>
      <Icon icon='icon-ai-option' color='white' />
      <Spreader spread={5} />
      {t('wizard.badge.new')}
    </Badge>
  )
}

const RecommendedBadge = () => {
  const { t } = useTranslation()

  return <Badge variant='warning-filled'>{t('wizard.badge.recommended')}</Badge>
}

export const CreateLandingWizard = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const close = () => navigate(LANDINGS.LANDINGS)

  const handleWizardClick = () => {
    navigate(LANDINGS.LANDINGS_CREATE_WIZARD_FIRST)
  }

  const handleTemplatesClick = () =>
    window.open(`${NEW_LANDINGI_APP_URL}/templates/v1`, '_self')

  const handleBlankTemplateClick = async () => {
    window.open(
      `${NEW_LANDINGI_APP_URL}/templates/v1/use/3945f246b1cd4ce2eea2?blank=1`,
      '_self'
    )
  }

  return (
    <motion.div
      initial={{ x: '-100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ ease: [0.37, 0, 0.39, 1], duration: 0.85 }}
    >
      <Row className={styles['choose-method']}>
        <Close className={styles.close} onClick={close} />

        <Column className={styles['choose-method__content']}>
          <div className={styles['choose-method__left-column-content']}>
            <Heading>{t('landings.create.wizard.landing.modal')}</Heading>

            <Spacer space={20} />

            <Spacer space={15} />

            <div className={styles['choose-method__box-outlines-grid']}>
              <ChooseMethodBoxOutline
                onClick={handleWizardClick}
                imageSrc={GenerateWithWizpage}
                heading={
                  <Fragment>
                    <Trans i18nKey='landings.create.wizard.landing.modal.create.with.wizard' />{' '}
                    <Text color='primary' weight={600}>
                      {t('word.wizpage')}
                    </Text>
                  </Fragment>
                }
                paragraph={t(
                  'landings.create.wizard.landing.modal.create.campaign.specific'
                )}
                badges={[<NewBadge />, <RecommendedBadge />]}
                buttonContent={t(
                  'landings.create.wizard.landing.modal.generate.website'
                )}
              />

              <ChooseMethodBoxOutline
                imageSrc={Templates}
                heading={t(
                  'landings.create.wizard.landing.modal.select.template'
                )}
                paragraph={t(
                  'landings.create.wizard.landing.modal.choose.one.of.400'
                )}
                onClick={handleTemplatesClick}
                buttonContent={t(
                  'landings.create.wizard.landing.modal.browse.templates'
                )}
              />

              <ChooseMethodBoxOutline
                imageSrc={BlankPage}
                heading={t(
                  'landings.create.wizard.landing.modal.start.with.blank.page'
                )}
                paragraph={t(
                  'landings.create.wizard.landing.modal.create.your.website.from.scratch'
                )}
                buttonContent={t(
                  'landings.create.wizard.landing.modal.use.blank.canva'
                )}
                onClick={handleBlankTemplateClick}
              />
            </div>
          </div>
        </Column>
      </Row>
    </motion.div>
  )
}
