import { Text } from '@ui-kit'
import { Column } from 'simple-flexbox'

import styles from './StyleBox.module.scss'

export const CustomStyleBox = () => {
  return (
    <Column
      alignItems='center'
      justifyContent='center'
      className={styles.panel}
    >
      <Text color='primary' weight={600} size={14}>
        Text: Custom style
      </Text>
    </Column>
  )
}
