import { KeyboardEventHandler, useCallback } from 'react'

const NOT_ALLOWED_KEYS_IN_NATURAL_NUMBER_INPUT = ['e', 'E', '+', '-', ',', '.']

export const useInputHelpers = () => {
  const escapeNotAllowedKeysFromNaturalNumberInput: KeyboardEventHandler<HTMLInputElement> =
    useCallback(event => {
      if (NOT_ALLOWED_KEYS_IN_NATURAL_NUMBER_INPUT.includes(event.key)) {
        event.preventDefault()
      }
    }, [])

  const escapePasteNotIntegerNumbers = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedData = event.clipboardData.getData('Text')
      const isInteger = Number.isInteger(Number(pastedData))

      if (!isInteger) {
        event.preventDefault()
      }
    },
    []
  )
  const restrictValueToRange = useCallback(
    ({
      value,
      min,
      max
    }: {
      value: number
      min: number
      max: number
    }): number => Math.min(Math.max(value, min), max),
    []
  )

  return {
    escapeNotAllowedKeysFromNaturalNumberInput,
    escapePasteNotIntegerNumbers,
    restrictValueToRange
  }
}
