import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { PAYMENTS } from '@routes/path'
import { Button } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

interface WillExpireTodayProps {
  buttonContent: string
}

export const WillExpireToday: FC<WillExpireTodayProps> = ({
  buttonContent
}) => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const handleOpenPaymentsPage = () => {
    window.open(`${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`, '_self')
  }

  return (
    <Row
      justifyContent='space-between'
      alignItems='center'
      style={{
        width: '100%'
      }}
    >
      {/* use InfobarContent component instead of <span> tag */}
      <span className={styles.alert__content}>
        {t('flash.account-expiredat-today-message')}
      </span>

      {hasAccessToPayments && (
        <Button size={14} onClick={handleOpenPaymentsPage}>
          {buttonContent}
        </Button>
      )}
    </Row>
  )
}

WillExpireToday.displayName = 'TopbarInfobarAccountExpiresAtWillExpireToday'
