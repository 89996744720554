import { calculateColorTints, convertRgbaColorToString } from '@helpers/color'
import { useStyles } from '@hooks/useStyles'
import { RGBColor } from '@ui-kit/types/color'
import { FC } from 'react'

import styles from './ColorTints.module.scss'

export type ColorTintsSize = 'small' | 'medium'

export interface ColorTintsProps {
  colorValue: RGBColor
  size?: ColorTintsSize
  className?: string
  'data-testid'?: string
}

export const ColorTints: FC<ColorTintsProps> = ({
  colorValue,
  size = 'small',
  className = '',
  'data-testid': dataTestId = 'color-tints'
}) => {
  const classNames = useStyles(
    {
      [styles.colorTints]: true,
      [styles[`colorTints--size-${size}`]]: size
    },
    className
  )
  const colorStyle = {
    backgroundColor: convertRgbaColorToString(colorValue)
  }

  const colorTints = calculateColorTints(colorValue)

  const colorTintsStyles = colorTints.map(color => ({
    backgroundColor: convertRgbaColorToString(color)
  }))

  return (
    <div className={classNames} data-testid={dataTestId}>
      <div
        className={styles.color}
        style={colorStyle}
        data-testid='main-color'
      />
      <div className={styles.tints}>
        {colorTintsStyles.map(colorTintStyle => (
          <div
            key={colorTintStyle.backgroundColor}
            className={styles.tint}
            style={colorTintStyle}
            data-testid='tint'
          />
        ))}
      </div>
    </div>
  )
}

ColorTints.displayName = 'ColorTints'
