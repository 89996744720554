import { useStyles } from '@hooks/useStyles'
import { Logo, StepsBar } from '@pages/Authentication/components/HeaderElements'
import { REGISTRATION } from '@routes/path'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import styles from './Header.module.scss'

const getHeaderElement = pathname => {
  if (pathname.includes(REGISTRATION.SURVEY.DEFAULT)) {
    return StepsBar
  }

  if (pathname.includes(REGISTRATION.CREDIT_CARD_STEP.DEFAULT)) {
    return StepsBar
  }

  return Logo
}

/**
 * stateless presentational component that renders different paragraphs in registration flow pages depending on route path
 * @returns {object} children
 */
const Header = ({ className }) => {
  const location = useLocation()
  const headerStyles = useStyles(
    {
      [styles.header]: true
    },
    className
  )
  const HeaderElement = getHeaderElement(location.pathname)

  return (
    <div className={headerStyles}>
      {HeaderElement ? <HeaderElement /> : null}
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

Header.displayName = 'Header'

export default Header
