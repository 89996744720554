import Medical from '@images/landings/wizard/landing-page-topics/medical.png'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { BoxOutline, BoxOutlineProvider, Image, Radio } from '@ui-kit'

import styles from '../Sixth.module.scss'

export const StyleBox = ({ topic }: { topic: string }) => {
  const { formik } = useWizardContext()
  const { values } = formik

  return (
    <BoxOutlineProvider controlledActive={values.topic === topic}>
      <BoxOutline
        padding={0}
        onClick={() => formik.setFieldValue('topic', topic)}
      >
        <Image src={Medical} alt={topic} width='100%' />

        <div className={styles['fourth-step__radio']}>
          <Radio
            label={topic}
            id={topic}
            field={{
              name: topic,
              value: values.topic,
              onChange: () => formik.setFieldValue('topic', topic)
            }}
          />
        </div>
      </BoxOutline>
    </BoxOutlineProvider>
  )
}
