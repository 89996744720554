import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { pallets } from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/constants'
import { useGetFontsList } from '@services/fonts/useGetFontsList'

import { getPreview } from './previews'

export const SecionPreview = ({ id }: { id: string }) => {
  const {
    formik: {
      values: {
        palette,
        style,
        customPalette: { colors, font }
      }
    }
  } = useWizardContext()

  const { data: fontListData } = useGetFontsList()

  const getData = () => {
    if (palette === 'custom') {
      // ... custom pallete
      return {
        colors,
        font
      }
    }

    if (!style) {
      return {
        colors: [],
        font: ''
      }
    }

    return pallets[style][palette]
  }

  const data = getData()

  const fontUrl = fontListData?.collection[data.font.toLowerCase()].css_url

  return (
    <div
      style={{
        width: '480px',
        height: '320px'
      }}
    >
      <link href={fontUrl} rel='stylesheet' />

      {/* TODO: it's only to prevent page crush on custom styles, until it's ready */}
      {getPreview({ id, data })}
    </div>
  )
}
