import { ADDONS_IDS } from '@constants/addonsIds'
import { popupsPrices } from '@constants/addonsPrices'
import { ADDONS_UUIDS } from '@constants/addonsUuids'
import { PLANS } from '@constants/plans'
import Popups from '@images/addons/icons/Popups.svg'
import {
  EDITOR,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { PopupsScheduleDemoButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import { BUYABLE, BuyableAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getPopupsAddon: (
  t: TFunction<'translation', undefined>
) => BuyableAddonTemplate = t => ({
  type: BUYABLE,
  name: t('addon.store.popups.name'),
  title: t('addon.store.popups.title'),
  tileDescription: <Trans i18nKey='addons.store.popups.description' />,
  badges: [EDITOR, SEVEN_DAYS_FREE_TRIAL],
  imageSrc: Popups,
  id: ADDONS_IDS.POPUPS_ADDON,
  uuid: ADDONS_UUIDS.POPUPS_ADDON,
  pageDescription: t('addons.store.popups.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  price: popupsPrices,
  pricing: getPricing(t),
  useCases: getUseCases(t),
  faq: getFAQ(t),
  featureFlag: 'POPUPS',
  secondaryButton: PopupsScheduleDemoButton,
  limitedInPlans: [PLANS.FREE_LIMITED_24, PLANS.LITE_LIMITED_24]
})
