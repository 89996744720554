import { Paragraph } from '@ui-kit/Paragraph'
import { FC, ReactNode } from 'react'

export interface PriceDescriptionProps {
  children?: ReactNode
  'data-testid'?: string
}

export const PriceDescription: FC<PriceDescriptionProps> = ({
  children,
  'data-testid': dataTestId = 'PriceDescription'
}) => {
  return (
    <Paragraph size={14} color='neutral-6' data-testid={dataTestId}>
      {children}
    </Paragraph>
  )
}
