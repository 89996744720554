import { MODAL_SUBSCRIPTION_EXPIRED } from '@components/Modals/ModalSubscriptionExpired'
import { MODAL_TRIAL_EXPIRED } from '@components/Modals/ModalTrialExpired'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { getSessionStorage, setSessionStorage } from '@helpers/session'
import { parseStorage } from '@helpers/storage'
import { ACCOUNT } from '@routes/path'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useInitialModals = () => {
  const { pathname } = useLocation()

  const exludePaths = [ACCOUNT.ACCOUNT_PACKAGE]

  const hasExludePath = exludePaths.includes(pathname)

  const { isLoading, user, isActive, isSupportMode, expiresAt } =
    useUserContext()

  const hasAccessToSpa = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  useEffect(() => {
    if (
      !user ||
      isLoading ||
      isSupportMode ||
      isActive ||
      !expiresAt?.formatted ||
      !hasAccessToSpa ||
      hasExludePath
    )
      return

    const today = new Date()
    const expiresAtDate = new Date(expiresAt.formatted?.replace(/-/g, '/'))

    if (today < expiresAtDate) return

    const { was_account_payment_registered } = user.payment

    const showSubscriptionExpiredModal = parseStorage(
      getSessionStorage(MODAL_SUBSCRIPTION_EXPIRED)
    )

    if (was_account_payment_registered && !showSubscriptionExpiredModal) {
      NiceModal.show(MODAL_SUBSCRIPTION_EXPIRED)
      setSessionStorage(MODAL_SUBSCRIPTION_EXPIRED, true)
    }

    if (!was_account_payment_registered) {
      NiceModal.show(MODAL_TRIAL_EXPIRED)
    }
  }, [
    isActive,
    isLoading,
    user,
    isSupportMode,
    expiresAt,
    hasAccessToSpa,
    hasExludePath
  ])
}
